// Importamos hooks de react que usaremos para este componente
import { FC, useContext, useState, useEffect } from 'react'
// Importamos componente Modal de la libreria de bootstrap para react
import { Modal } from 'react-bootstrap'
// Importamos libreria para manejo de lenguaje
import { useIntl } from 'react-intl'
// Importamos constante con prefijo para el titulo de la pagina
import { DragonCem } from '../../utils/constants/_Constants'
// Importamos helpers de metronic
import { KTIcon } from '../../../_metronic/helpers'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from '../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos el componente CardBrands
import { CardBrands } from './aux-components/CardBrands'
// Importamos el componente ModalNewBrand
import { ModalNewBrand } from './modals/ModalNewBrand'
// Importamos componnte para exportacion de registros
import { DropdownExportBrands } from '../../../_metronic/partials/content/dropdown/DropdownExportBrands'
// Importamos componente con la animacion de carga de registros
import { LoadingAnimation } from '../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

// Importamos la funcion bransListByClient para conexion con API de marcas
import { brandsListByClient } from './services/_BrandsListApi'
// Importamos la funcion brandsNew para conexion con API de marcas
import { brandsNew } from './services/_BrandsNewApi'

// ---------------------------------------------------------------------------------------

// Importamos funciones sessionExpired, dateFormatter y countArrayObjects
// para vencimiento de sesion, formateo de fecha y conteo de objetos de arrays
import {
  sessionExpired,
  dateFormatterTextualEn,
  countArrayObjects,
  setPageTitle,
} from '../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo } from '../../utils/interfaces/_InterfacesTypes'
// Importamos las interfaces de marcas que usaremos para este componente
import { Brand, FormData } from './interfaces/brandsTypes'

// Iniciamos funcion para construir componente Brands
const Brands: FC = () => {
  console.log('inicia renderizacion del componente brands')

  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

  //Variables para manejo de estado del filtro de estatus
  const [selectedFilter, setSelectedFilter] = useState<number>(1)

  //Variables para manejo de estado de la paginacion
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage: number = 9

  //Variables para manejo de estado de errores en inputs del formulario
  const [errors, setErrors] = useState<{[key: string]: string}>({})

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Variables con el estado de la alerta da confirmacion o error
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  //Variables para el manejo de estado del modal de creacion de marca
  const [showModal, setShowModal] = useState<boolean>(false)

  //Varibles para el manejo de estado del arreglo con las marcas del cliente
  const [brandsList, setBrandsList] = useState<Brand[]>([])

  //Variable con el estado inicial del formulario de creacion de marca
  const initialFormNewBrand: FormData = {
    brandName: '',
    crm: '',
    brandProducts: [],
    roleId: '',
  }

  //Variables para el manejo de estado de la informacion del formulario
  const [formData, setFormData] = useState<FormData>(initialFormNewBrand)

  //Variables para el manejo del estado de la información de las marcas que se exportaran en csv y xlsx
  const [exportData, setExportData] = useState({})

  //Funcion para resetear formulario
  const resetForm = () => {
    setFormData(initialFormNewBrand)
  }

  //Funcion para abrir modal
  const openModal = () => {
    setShowModal(true)
  }

  //Funcion para cerrar modal
  const closeModal = () => {
    setErrors({})
    setShowModal(false)
    resetForm()
  }

  //Función para definir el estado al momento de dar click
  const handleSetExport = () => {
    setExportData(filterDataToExport)
    console.log('Se definió');
    console.log(filterDataToExport);
  }

  //Funcion para determinar el tamaño del arreglo de listas de marcas
  const checkBrandsListLength = () => {

    //Si el arreglo tiene un tamaño igual a cero mandamos mensaje de marcas no existentes
    brandsList.length === 0 && setInfoMessageLoading('No has creado ninguna marca')

  }

  //Funcion para conectarnos al servidor y obtener el listado de marcas del cliente
  const getBrandsRegisters = async () => {

    try {
      const { data: jsonData, code, msg } = await brandsListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
        setIsLoading(false)
        return
      } else {

        const brandsListReceived: Brand[] = jsonData.data

        const userTokenNew: string = jsonData.token

        // Mandamos a actualizar el token
        updateToken(userTokenNew)

        console.log(brandsListReceived);

        const brandsCards : Brand[]= brandsListReceived.map((brand: Brand) => {
          //Variable con la funcion para dar formato a la fecha
          const brandDateAddFormatted: string = dateFormatterTextualEn(brand.date_add)

          //Variable con la funcion para contar el total de productos
          const totalBrandProducts: number = countArrayObjects(brand.products)

          // console.log('productos asignados: ', brand.products);

          return {
            bid: brand.bid,
            date_add: brandDateAddFormatted,
            date_modify: brand.date_modify,
            logo: brand.logo,
            name: brand.name,
            status: brand.status,
            crm_id: brand.crm_id,
            products: brand.products,
            productsAssigned: totalBrandProducts,
          }

        })

        setBrandsList(brandsCards)
        //Ocultamos la animacion de carga
        setIsLoading(false);
        /* Ejecutamos la funcion para verificar  
        el tamaño del arreglo de marcas */
        checkBrandsListLength()

      }
    } catch (error) {
      setInfoMessageLoading("Ocurrio un error al intentar obtener las marcas de este cliente");
      setIsLoading(false);
      // console.error("Error receiving brands: ", error);
    }
  }

  //Use effect para ejecutar la funcion getBrandsRegisters despues de que el componente Brands se reenderizo
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: 'BRANDS.TITLE' })} | ${DragonCem}`)

    getBrandsRegisters()
  }, [selectedFilter])

  //Funcion para el envio del formulario de creacion de marca
  const handleFormSubmit = async () => {

    setErrors({})

    if (!formData.brandName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        brandName: 'Debes dar un nombre a tu marca',
      }))
      return
    }

    if (!formData.roleId) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        roleId: 'Debes de seleccionar un role activo',
      }))
      return
    }

    const name_brand: string = formData.brandName
    const crm_id: string | undefined = formData.crm
    const brandsProductsArray: string[] | undefined = formData.brandProducts
    const roleId: string = formData.roleId

    // console.log('estas son los productos mandados', brandsProductsArray);
    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await brandsNew(name_brand, crm_id, brandsProductsArray, roleId, clientId, userToken)

      // console.log(code);
      // console.log(msg);

      // const userTokenNew = jsonData.token;

      // console.log('actualiza token brandNew (brands): ', userTokenNew)
      // updateToken(userTokenNew);

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({ id: 'BRANDS.MODAL_NEW.SUCCESS_BRAND_CREATION' })
              : intl.formatMessage({ id: 'BRANDS.MODAL_NEW.FAIL_BRAND_CREATION' }),
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModal()
          // Después de agregar una nueva marca, actualiza la lista de marcas
          getBrandsRegisters()
          // Vuelve a la primera página
          setCurrentPage(1)
        }, 3000)
      }
    } catch (error) {
      // console.error("Error en el guardado de la marca", error);
      setAlertInfo({
        text: 'Error en la solicitud Axios',
        variant: 'danger',
      })
    }
  }

  //Definimos una variable independiente de una función para ser la que
  //cambie constantemente y guarde la data de lo que se exportará
  let filterDataToExport = {};

  //Funcion para obtener el total de registros de marcas que llegaron
  const getTotalEntries = () => {
    const filteredEntries = brandsList.filter((brand) => {
      if (selectedFilter === 0) return true
      if (selectedFilter === 1) return brand.status === true
      if (selectedFilter === 2) return brand.status === false
      return false
    })

    // Mostrar el contenido filtrado en la consola
    // console.log('Contenido filtrado:', filteredEntries)
    filterDataToExport = filteredEntries;

    return filteredEntries.length
  }

  //Funcion para reenderizar cada tarjeta de marca
  const renderBrandCards = (getBrandsRegisters: () => void) => {
    return brandsList
      .filter((brand) => {
        if (selectedFilter === 0) return true
        if (selectedFilter === 1) return brand.status === true
        if (selectedFilter === 2) return brand.status === false
        return false
      })
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((brand, index) => (
        <div key={index} className='col-sm-6 col-xl-4'>
          <CardBrands
            idBrand={brand.bid}
            logoBrand={`https://storage.googleapis.com/dragon-bucket-prod/appv2/core/${brand.logo}`}
            nameBrand={brand.name}
            dateAddBrand={brand.date_add}
            dateUpdateBrand={brand.date_modify}
            statusBrand={brand.status}
            productsBrand={brand.products}
            crmBrand={brand.crm_id}
            productsAssigned={brand.productsAssigned}
            getBrandsRegisters={getBrandsRegisters}
          />
        </div>
      ))
  }

  //Funcion para reenderizar el control de paginas (paginacion)
  const renderPagination = () => {
    return (
      <ul className='pagination'>
        {Array.from({ length: Math.ceil(getTotalEntries() / itemsPerPage) }).map((_, index) => {
          const page = index + 1
          const totalPages = Math.ceil(getTotalEntries() / itemsPerPage)

          if (
            (page <= 3 && currentPage <= 5) ||
            (page > currentPage - 2 && page < currentPage + 2) ||
            (page > totalPages - 2 && currentPage >= totalPages - 4)
          ) {
            return (
              <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                <button className='page-link' onClick={() => setCurrentPage(page)}>
                  {page}
                </button>
              </li>
            )
          }

          if (
            (page === 4 && currentPage > 5) ||
            (page === totalPages - 1 && currentPage < totalPages - 4)
          ) {
            return (
              <li key={index} className='page-item disabled'>
                <span className='page-link'>...</span>
              </li>
            )
          }

          return null
        })}
      </ul>
    )
  }

  //Funcion para reenderizar el dropdown para filtro de estatus
  const renderFilterDropdown = () => {
    return (
      <div className='w-150px me-5'>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-white form-select-sm'
          defaultValue='1'
          onChange={(e) => {
            setSelectedFilter(Number(e.target.value))
            setCurrentPage(1)
          }}
        >
          <option value='0'>{intl.formatMessage({ id: 'BRANDS.TITLE.FILTER_ALL' })}</option>
          <option value='1'>{intl.formatMessage({ id: 'BRANDS.TITLE.FILTER_ACTIVE' })}</option>
          <option value='2'>{intl.formatMessage({ id: 'BRANDS.TITLE.FILTER_UNACTIVE' })}</option>
        </select>
      </div>
    )
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>
                {intl.formatMessage({ id: 'BRANDS.TITLE' })}
                <span className='fs-6 text-gray-400 fw-bold ms-1'></span>
              </h1>
              <div className='d-flex align-items-center my-2'>
                {renderFilterDropdown()}
                <button type='button' className='btn btn-primary btn-sm me-5' onClick={openModal}>
                  {intl.formatMessage({ id: 'BRANDS.BUTTON.NEW_BRAND' })}
                </button>

                <button
                  type='button'
                  className='btn btn-primary d-flex align-items-center btn-sm me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={handleSetExport}
                >
                  <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                  <span>Exportar datos</span>
                </button>
                <DropdownExportBrands dataToExport={exportData} title={'Brands'} />

                <Modal show={showModal} onHide={closeModal} scrollable className='modal-default'>
                  <Modal.Header closeButton onClick={resetForm}>
                    <Modal.Title>
                      <h1>{intl.formatMessage({ id: 'BRANDS.MODAL_NEW.TITLE' })}</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ModalNewBrand formData={formData} setFormData={setFormData} errors={errors} setErrors={setErrors}/>
                  </Modal.Body>
                  {alertInfo.text && (
                    <div className='px-20'>
                      <div
                        className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                        role='alert'
                      >
                        <div>{alertInfo.text}</div>
                      </div>
                    </div>
                  )}
                  <Modal.Footer>
                    <button
                      className='btn btn-sm btn-secondary'
                      type='button'
                      onClick={() => {
                        closeModal()
                        resetForm()
                      }}
                    >
                      {intl.formatMessage({ id: 'BRANDS.MODAL_NEW.RESET_BUTTON' })}
                    </button>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      onClick={handleFormSubmit}
                    >
                      {intl.formatMessage({ id: 'BRANDS.MODAL_NEW.SUBMIT_BUTTON' })}
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className='row g-6 g-xl-9' id='card-brand-container'>

              {/* Validaciones para determinar que elementos mostraremos en la pantalla */}

              {/* Si isLoading es igual a 'true' lo mostramos */}
              {isLoading && <LoadingAnimation alignment='center' label='Obteniendo marcas...' size='lg' />}

              {/* Si isLoading es diferente de 'true' y el tamaño de brandsList
              es mayor a 0 mostramos el componente de tarjetas de marcas */}
              {!isLoading && brandsList.length > 0 && (
                renderBrandCards(getBrandsRegisters)
              )}

              {/* Si isLoading es diferente de 'true' y el tamaño de brandsList
              es igual a 0 mostramos el mensaje informativo */}
              {!isLoading && brandsList.length === 0 && (
                <p>{infoMessageLoading}</p>
              )}

            </div>
            <div className='d-flex flex-stack flex-wrap pt-10'>
              <div className='fs-6 fw-bold text-gray-700'>
                {`${intl.formatMessage({ id: 'BRANDS.PAGINATION_SHOWING' })} ${(currentPage - 1) * itemsPerPage + 1
                  } ${intl.formatMessage({ id: 'BRANDS.PAGINATION_TO' })} ${Math.min(
                    currentPage * itemsPerPage,
                    getTotalEntries()
                  )} ${intl.formatMessage({
                    id: 'BRANDS.PAGINATION_OF',
                  })} ${getTotalEntries()} ${intl.formatMessage({ id: 'BRANDS.PAGINATION_RESULTS' })}`}
              </div>
              {renderPagination()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Brands };