// Importamos hooks de react que usaremos para este componente
import { useState, useContext, useEffect } from "react";
// Importamos libreria para manejo de lenguaje
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Improtamos componente animacion de carga
import { LoadingAnimation } from "../../../../utils/components/loading/LoadingAnimation";

// ---------------------------------------------------------------------------------------

// Importamos funcion campaignsMediumList para conexion con API de campañas
import { campaignsMediumList } from "../../services/_CampaignsMediumListApi";
// Importamos funcion manualCampaignsNew para conexion con API de campañas
import { manualCampaignsNew } from "../../services/_CampaignsNewApi";
// Importamos la funcion rolesListByClient para conexion con API de roles
import { rolesListByClient } from "../../../assigns/services/_RolesListApi";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo, CampaignMedium, CrmSetup, MailTemplate, WabaBot, WabaTemplate, GradeSetupList, GradeSetup } from "../../../../utils/interfaces/_InterfacesTypes";
// Importamos las interfaces de roles que usaremos en este componente
import { Role } from "../../../assigns/interfaces/rolesTypes";

// ---------------------------------------------------------------------------------------

// Importamos funcion para el vencimiento de sesion
import { convertObjectsToText, sessionExpired } from "../../../../utils/functions/_Functions";

import { gradesSetupListByProduct } from "../../../../utils/services/_GradesSetupListApi";

import { mailTemplatesListByProduct } from "../../../../utils/services/_MailTemplatesApi";

import { wabaTemplatesListByProduct } from "../../../../utils/services/_WabaTemplatesApi";
import { Template } from '../../../twlio-services/twlio-templates/interfaces/TwlioTemplates';

// Iniciamos funcion para construir componente ManualTabForm
function ManualTabForm({ productId, formDataManual, setFormDataManual, closeModal, getCampaignsRegisters, setCurrentPage }) {

    const initialCrmSetupList: CrmSetup = {
        client_id: '',
        crm_setups: [
            {
                crm_id: '',
                crm_name: '',
                crm_description: '',
            }
        ]
    }

    const initialMailTemplatesList: string[] = ['']

    const initialWabaBotsList: WabaBot = {
        client_id: '',
        wab_bots: [
            {
                wab_bot_id: '',
                wab_bot_name: '',
                wab_bot_description: '',
            }
        ]
    }

    const initialWabaTemplatesList: WabaTemplate = {
        templates: [
            {
                id_content: '',
                friendly_name: ''
            }
        ]
    } 

    const initialGradesSetupList: GradeSetup = {
        "standard": {
            "1": "No Localizable",
            "2": "No interesado",
            "3": "Interes Bajo",
            "4": "Interes Medio",
            "5": "Alto Interes"
        }
    }

    const [rolSelected, setRolSelected] = useState<string>('');

    const [campaignsMediumListReceived, setCampaignsMediumListReceived] = useState<CampaignMedium[]>([]);
    const [crmSetupsList, setCrmSetupsList] = useState<CrmSetup>(initialCrmSetupList)
    const [mailTemplatesList, setMailTemplatesList] = useState<string[]>(initialMailTemplatesList)
    const [wabaBotsList, setWabaBotsList] = useState<WabaBot>(initialWabaBotsList)
    const [wabaTemplatesList, setWabaTemplatesList] = useState<WabaTemplate>(initialWabaTemplatesList)
    const [gradesSetupsList, setGradesSetupsList] = useState<GradeSetup>(initialGradesSetupList)
    const [gradesCategoryKeys, setGradesCategoryKeys] = useState<string[]>(['standard'])
    const [gradesSetupJson, setGradesSetupJson] = useState<{[key: string]: string } | null>(null);
    const [gradesSetupJsonText, setGradesSetupJsonText] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isWabaBodyVisible, setIsWabaBodyVisible] = useState<boolean>(false)
    const [mainMessage, setMainMessage] = useState<string>('')
    const [qualityMessage, setQualityMessage] = useState<string>('')

    const [errors, setErrors] = useState({
        name: '',
        connectionType: '',
        medium: '',
        roleId: '',
        wab_messages: ''
    });

    //Algunas variables desde el usercontext
    const { user, token, updateToken } = useContext(UserContext);
    const clientId = user.cli_id;
    const userToken = token;

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            name: '',
            connectionType: '',
            medium: '',
            roleId: '',
            wab_messages: ''
        };

        if (!formDataManual.name) {
            newErrors.name = 'El nombre de la campaña es requerido';
            valid = false;
        }

        if (!formDataManual.connectionType) {
            newErrors.connectionType = 'El tipo de conexión es requerido';
            valid = false;
        }

        if (!formDataManual.medium) {
            newErrors.medium = 'El medio es requerido';
            valid = false;
        }

        if (!formDataManual.roleId) {
            newErrors.roleId = 'El rol es requerido';
            valid = false;
        }

        // if (!formDataManual.wab_messages) { 
        //     newErrors.wab_messages = 'El mensaje es requerido';
        //     valid = false;
        // }

        setErrors(newErrors);
        return valid;
    };

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const [rolesList, setRolesList] = useState<Role[]>([]);

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    })

    const handleChangeRol = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const valueSelected: string = e.target.value;
        const idSelected: string = e.target.options[e.target.selectedIndex].id;
        setRolSelected(valueSelected);

        const scheduleDiv = document.getElementById('schedules');

        if (valueSelected !== '0') {
            if (scheduleDiv) {
                scheduleDiv.style.display = 'none';
            }
        } else {
            if (scheduleDiv) {
                scheduleDiv.style.display = 'block';
            }
        }
        console.log("Value selected:", valueSelected);
        console.log("ID selected:", idSelected);

        setFormDataManual(prevState => ({
            ...prevState,
            roleId: idSelected
        }));
    }

    const handleMediumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const valueSelected: string = e.target.value;
        const idSelected: string = e.target.options[e.target.selectedIndex].id; // Obtener el ID de la opción seleccionada

        console.log("Value selected:", valueSelected);
        console.log("ID selected:", idSelected);

        setFormDataManual(prevState => ({
            ...prevState,
            medium: idSelected, // Aquí se actualiza medium con idSelected en lugar de valueSelected
            channelId: valueSelected // Agregar el valor seleccionado a formDataManual
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

        // Imprimir estado antes del cambio
        console.log('Estado anterior:', formDataManual);

        if (name === 'channelId') {
            handleMediumChange(e as React.ChangeEvent<HTMLSelectElement>);
        } else {
            setFormDataManual(prevState => ({
                ...prevState,
                [name]: newValue
            }));
        }

        // Imprimir estado después del cambio
        console.log('Estado posterior:', formDataManual);
    };

    const getBodyWabaTemplate = (idWaba: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        
        setIsWabaBodyVisible(true)
        setIsLoading(true)
        const {name, value, type} = idWaba.target;
        console.log('id del waba template: ', value)

        setTimeout(() => {
            if(value===''){
                setIsWabaBodyVisible(false)
            }else if (value==='HX2df10bb33831beaf776faa7dcdc8c4c5'){
                setMainMessage('¡*Hola {{1}}*! ¡Qué bueno saber que estás interesado en *{{2}}*! Hemos recibido tu solicitud a través de *{{4}}* y ya te hemos puesto en contacto con {{3}}, uno de nuestros Asesores Comerciales quién está para ayudarte. Nuestro asesor se pondrá en contacto contigo pronto para brindarte toda la información que necesites y responder a tus preguntas. Mientras tanto, si lo prefieres, puedes conocer más de {{2}} a través de estas opciones:')
                setQualityMessage('¡Hola *{{1}}* !En días anteriores solicitaste información acerca de *{{2}}* por lo que nos gustaría saber si se puso en contacto contigo el Asesor asignado y te respondió todas tus dudas de manera correcta:')
            }else{
                setMainMessage('No se encontró mensaje principal')
                setQualityMessage('No se encontro mensaje quality')
            }
    
            setIsLoading(false)

        }, 1000);

    }

    const handleGradeSetUp = (gradeCategory: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value, type} = gradeCategory.target;
        console.log('categoria: ', value)
        console.log({gradesSetupsList})

        const gradesSetupSelected = gradesSetupsList[value]

        console.log(gradesSetupSelected)

        const formattedGradesSetupSelected = convertObjectsToText(gradesSetupSelected)

        // const formattedGradesSetupSelected = Object.entries(gradesSetupSelected)
        //     .map(([key, value]) => `${key}: ${value.toLowerCase()}`)
        //     .join('\n');

        setGradesSetupJson(gradesSetupSelected)
        setGradesSetupJsonText(formattedGradesSetupSelected)

    } 

    const handleFormSubmit = async () => {

        if (!validateForm()) {
            setAlertInfo({
                text: 'Por favor, completa todos los campos requeridos.',
                variant: 'danger',
            });
            return;
        }

        const channelId = formDataManual.channelId;
        const connectionType = formDataManual.connectionType;
        const medium = formDataManual.medium;

        const name = formDataManual.name;
        const mailTemplate = formDataManual.mailTemplate;
        const channelCrmId = formDataManual.channelCrmId;
        const crmId = formDataManual.crmId;
        const startHour = formDataManual.startHour;
        const endHour = formDataManual.endHour;
        const quality = formDataManual.quality;
        const iscore = formDataManual.iscore;
        const roleId = formDataManual.roleId;

        // console.log('channelid: '+channelId);
        // console.log('connectype: '+connectionType);
        // console.log('medio: '+medium);
        // console.log('name: '+name);
        // console.log('mailtemp: '+mailTemplate);
        // console.log('channelcrmid: '+channelCrmId);
        // console.log('starth: '+startHour);
        // console.log('endh: '+endHour);
        // console.log('quality: '+quality);
        // console.log('iscore: '+iscore);

        // console.log(roleId)

        try {

            const { data: jsonData, code, msg } = await manualCampaignsNew(productId, clientId, channelId, connectionType, medium, name, mailTemplate, channelCrmId, crmId, startHour, endHour, quality, iscore, roleId, userToken);

            if (code === 401 && msg === 'Token no valido') {

                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? intl.formatMessage({ id: "CAMPAIGNS.MODAL_NEW.SUCCESS_CAMPAIGN_CREATION" }) : intl.formatMessage({ id: "CAMPAIGNS.MODAL_NEW.FAIL_CAMPAIGN_CREATION" }),
                    variant: code === 200 ? 'success' : 'danger',
                });

                if (code === 200) {
                    setTimeout(() => {
                        setAlertInfo({
                            text: '',
                            variant: '',
                        });
                        closeModal();
                        getCampaignsRegisters();
                        setCurrentPage(1);
                    }, 3000);
                }

            }

        } catch (error) {

            setAlertInfo({
                text: 'Error en la solicitud Axios',
                variant: 'danger',
            });

        }

    };

    const getMediumList = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const connectionTypeValue = e.target.value;
        console.log("Valor seleccionado:", connectionTypeValue);

        try {

            if (connectionTypeValue !== '') {

                const { data: jsonData, code, msg } = await campaignsMediumList(userToken, connectionTypeValue);

                if (code === 401 && msg === 'Token no valido') {

                    sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                    return;

                } else {

                    const campaignsMediumListReceived = jsonData.data;

                    setCampaignsMediumListReceived(campaignsMediumListReceived);

                    const userTokenNew = jsonData.token;

                    updateToken(userTokenNew);

                    console.log(campaignsMediumListReceived)
                }

            }

        } catch (error) {
            console.error('Error obteniendo el listado de canales: ', error)
        }

    }

    const getRolesRegisters = async () => {

        try {

            const { data: jsonData, code, msg } = await rolesListByClient(userToken, clientId);

            if (code === 401 && msg === 'Token no valido') {

                sessionExpired('La sesion ha expirado');
                return;

            } else {

                const rolesListReceived = jsonData.assigns;

                console.log(rolesListReceived);

                const userTokenNew = jsonData.token;

                updateToken(userTokenNew);

                setRolesList(rolesListReceived);

            }

        } catch (error) {
            console.error('No se pudo obtener el listado de roles: ', error);
        }
    }

    const getCrmSetups = () => {

        const crmSetupListReceived: CrmSetup = {
            client_id: 'API-DFGO-06YO',
            crm_setups: [
                {
                    crm_id: 'CRMSTP-API-001',
                    crm_name: 'HubSpot DragonCEM',
                    crm_description: '',
                },
                {
                    crm_id: 'CRMSTP-API-002',
                    crm_name: 'Zoho DragonCEM',
                    crm_description: '',
                },
                {
                    crm_id: 'CRMSTP-API-003',
                    crm_name: 'Odoo DragonCEM',
                    crm_description: '',
                }
            ]
        }

        setCrmSetupsList(crmSetupListReceived)

    }

    const getMailTemplates = async (): Promise<void> => {
        try {
            const {data: jsonData, code, msg} = await mailTemplatesListByProduct(userToken, productId)

            const mailTemplatesReceived: string[] = jsonData.data 

            const newUserToken: string = jsonData.token 
            
            updateToken(newUserToken)

            console.log(mailTemplatesReceived)

            setMailTemplatesList(mailTemplatesReceived)

        }catch (error){
            console.error('Error obteniendo los mail templates: ', error)
        }

    }

    const getWabaBots = () => {

        const wabaBotsListReceived: WabaBot = {
            client_id: 'API-DFGO-06YO',
            wab_bots: [
                {
                    wab_bot_id: 'WBOT-API-001',
                    wab_bot_name: 'botGralDragonCEM',
                    wab_bot_description: ''
                },
                {
                    wab_bot_id: 'WBOT-API-002',
                    wab_bot_name: 'botGeneralbaahrPrueba',
                    wab_bot_description: ''
                }
            ]
        }

        setWabaBotsList(wabaBotsListReceived)
    }

    const getWabaTemplates = async (): Promise<void> => {
        try{
            const {data: jsonData, code, msg} = await wabaTemplatesListByProduct(userToken, productId)

            const wabaTemplatesReceived = jsonData.templates

            const newUserToken: string = jsonData.token 

            updateToken(newUserToken)

            console.log(wabaTemplatesReceived)

            setWabaTemplatesList({
                templates: wabaTemplatesReceived
            })

            // setWabaTemplatesList(wabaTemplatesReceived)

        }catch (error){
            console.error('Error obteniendo los waba templates: ', error)
        }

        // const wabaTemplatesListReceived: WabaTemplate = {
        //     client_id: '',
        //     wab_templates: [
        //         {
        //             wab_template_id: 'WTMP-API-001',
        //             wab_template_name: 'DragonCEM DaaD',
        //             wab_template_number: '',
        //             wab_template_description: '',
        //         },
        //         {
        //             wab_template_id: 'WTMP-API-002',
        //             wab_template_name: 'DragonCEM Caver',
        //             wab_template_number: '',
        //             wab_template_description: '',
        //         },
        //         {
        //             wab_template_id: 'WTMP-API-003',
        //             wab_template_name: 'DragonCEM Brasaas',
        //             wab_template_number: '',
        //             wab_template_description: '',
        //         }
        //     ]

        // }

        // setWabaTemplatesList(wabaTemplatesListReceived)
    }

    const getGradesSetups = async (): Promise<void> => {
        try {
            const {data: jsonData, code, msg} = await gradesSetupListByProduct(userToken, productId)

            // if(code===401 && msg==='Token no valido'){
            //     sessionExpired('La sesion ha expirado')
            //     return
            // }else{
                const gradesSetups = jsonData.data 
                const categoryKeys = Object.keys(gradesSetups)

                console.log({gradesSetups})

                gradesSetups && Object.keys(gradesSetups).length > 0 && (() => {
                    setGradesSetupsList(gradesSetups);
                    setGradesCategoryKeys(categoryKeys);
                })();          
                
            // }

        }catch (error){
            console.error('Error obteniendo el lsitado de configuracion de calificaciones: ', error)
        }

    }

    useEffect(() => {
        getRolesRegisters();
        getCrmSetups();
        getMailTemplates();
        getWabaBots();
        getWabaTemplates();
        getGradesSetups();
    }, [])

    return (
        <>
            <div>

                <h3>Configura la campaña</h3>
                <span className="text-muted">Si no encuentras el medio que buscas contacta con tu asesor de ventas</span>

                <input
                    type="text"
                    placeholder="Ingresa el nombre de la campaña"
                    name="name"
                    className="form-control form-control-solid mt-4"
                    onChange={handleInputChange}
                />
                {errors.name && <div className="text-danger mb-6">{errors.name}</div>}

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select
                            className="form-select form-select-solid"
                            name="connectionType"
                            value={formDataManual.connectionType}
                            onChange={(e) => {
                                handleInputChange(e);
                                getMediumList(e);
                            }}
                        >
                            <option value=''>Tipo de conexión</option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                            <option value="WhatsApp">WhatsApp</option>
                        </select>
                        {errors.connectionType && <div className="text-danger mb-6">{errors.connectionType}</div>}
                    </div>

                    <div className="col-6">
                        <select
                            className="form-select form-select-solid"
                            name="channelId"
                            value={formDataManual.channelId}
                            onChange={handleInputChange}
                        >
                            <option value="">Medio</option>
                            {campaignsMediumListReceived.map((campaignMedium, index) => (
                                <option key={index} id={campaignMedium.name} value={(campaignMedium.channel_id)}>
                                    {campaignMedium.name}
                                </option>
                            ))}
                        </select>
                        {errors.medium && <div className="text-danger mb-6">{errors.medium}</div>}
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select className="form-select form-select-solid" onChange={(e) => {
                            getBodyWabaTemplate(e)
                        }}>
                            <option value=''>Configuración de WhatsApp</option>
                            {/* <option value = ''>General DragonCEM</option> */}
                            {wabaTemplatesList.templates.map((template, index) => (
                                <option key={index} id={template.id_content} value={template.id_content}>
                                    {template.friendly_name}
                                </option>
                            ))}
                        </select>
                        {errors.wab_messages && <div className="text-danger mb-6">{errors.wab_messages}</div>}
                    </div>

                    <div className="col-6">
                        <select className="form-select form-select-solid">
                            <option value=''>Selecciona Bot</option>
                            {/* <option value="botGralDragonCEM">botGralDragonCEM</option> */}
                            {wabaBotsList.wab_bots.map((wabaBot, index) => (
                                <option key={wabaBot.wab_bot_id} id={wabaBot.wab_bot_id} value={wabaBot.wab_bot_id}>
                                    {wabaBot.wab_bot_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {isWabaBodyVisible && (
                    <div className="d-flex mt-4">

                        {isLoading && (
                            <LoadingAnimation alignment='center' label='Obteniendo información del template...' size='sm'/>
                        )}

                        {!isLoading && (
                            <>     
                                <div className="col-6 me-2">
                                    <label className="mb-3">Mensaje principal</label>
                                    <textarea className="form-control form-control-solid text-muted" rows={4} readOnly={true} value={mainMessage}>
                                        {mainMessage}
                                    </textarea>
                                </div>

                                <div className="col-6">
                                    <label className="mb-3">Mensaje quality</label>
                                    <textarea className="form-control form-control-solid text-muted" rows={4} readOnly={true} value={qualityMessage}>
                                        {qualityMessage}
                                    </textarea>
                                </div>
                            </>
                        )}

                    </div>
                )}

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <select
                            className="form-select form-select-solid"
                            name="mailTemplate"
                            onChange={handleInputChange}
                        >
                            <option value=''>Mail template</option>
                            {/* <option value="b2c_notification">b2c_notification</option> */}
                            {mailTemplatesList.map((mailTemplate, index) => (
                                <option key={index} id={mailTemplate} value={mailTemplate}>
                                    {mailTemplate}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-6">
                        <select className="form-select form-select-solid">
                            <option value="">Configuración CRM</option>
                            {/* <option value="Hubspot DragonCEM">Hubspot DragonCEM</option> */}
                            {crmSetupsList.crm_setups.map((crmSetup, index) => (
                                <option key={crmSetup.crm_id} id={crmSetup.crm_id} value={crmSetup.crm_id}>
                                    {crmSetup.crm_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 me-2">
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Canal CRM ID"
                            name="channelCrmId"
                            value={formDataManual.channelCrmId}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-6">
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="CRM ID"
                            name="crmId"
                            value={formDataManual.crmId}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <h3 className="mt-6 mb-3">Calificaciones bot</h3>
                <div className="d-flex">
                    <div className="col-6 me-2">
                        <label className="mb-3">Configuración de calificaciones</label>
                        <select 
                            className="form-select form-select-solid" 
                            onChange={(e) => {
                                handleGradeSetUp(e)
                            }}
                        >
                            <option value=''>Configuracion calificaciones</option>
                            {/* <option value="1">5 ponderadores</option>
                            <option value="2">7 ponderadores</option> */}
                            {gradesCategoryKeys.map((gradeCategory) => (
                                <option key={gradeCategory} value={gradeCategory}>
                                    {gradeCategory}
                                </option>
                            ))}
                        </select>
                        <span className="text-muted mt-4">Selecciona un bundle y visualiza su configuración en el cuadro de 'Ponderadores'. Si no seleccionas uno, se guardará el predeterminado.</span>
                    </div>

                    <div className="col-6">
                        <label className="mb-3">Ponderadores</label>
                        <textarea className="form-control form-control-solid text-muted" value={gradesSetupJsonText} rows={4} readOnly={true}>
                            {gradesSetupJsonText}
                        </textarea>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="col-6 me-2">
                        <h3 className="mt-6 mb-3">Rol</h3>
                        <label className="mb-3">Roles activos</label>
                        <select className="form-select form-select-solid" value={formDataManual.roleId} onChange={handleChangeRol} name="roles">
                            <option value=''>Roles configurados</option>
                            {rolesList.map((role, index) => (
                                <option key={index} id={role.role_id} value={role.role_id}>
                                    {role.role_name}
                                </option>
                            ))}
                        </select>
                        <span className="text-muted mt-4">Selecciona uno de los roles previamente configurados y guardados en este cliente.</span>
                        {errors.roleId && <div className="text-danger mb-6">{errors.roleId}</div>}
                    </div>

                    <div className="col-6">
                        <h3 className="mt-6 mb-3">Quality</h3>
                        <label className="mb-6">Encuesta quality automática</label>&nbsp;&nbsp;&nbsp;
                        <div className="form-check form-switch mb-5">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="toggleSwitchQa"
                                // checked={formDataManual.quality}
                                defaultChecked={formDataManual.quality}
                                name="quality"
                                onChange={handleInputChange}
                            />
                        </div>
                        <span className="text-muted">Enciende o apaga el switch dependiendo si quieres que se envien encuestas QA de manera automatica.</span>
                    </div>
                </div>

                {/* <div id="schedules">
                    <h3 className="mt-6 mb-3">Horario de llamadas</h3>
                    <span className="text-muted">Selecciona los días y horario en que tus vendedores recibirán llamadas</span>

                    <div className="d-flex mt-4">
                        <label>
                            <input type="checkbox" className="form-check-input"/> Lunes
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Martes
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Miercoles
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Jueves
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Viernes
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Sabado
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <label>
                            <input type="checkbox" className="form-check-input"/> Domingo
                        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>

                    <div className="d-flex mt-8">
                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="startHour">Hora de inicio:</label>
                            </div>

                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="startHour"
                                    name="startHour"
                                    value={formDataManual.startHour}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-6 me-3">
                            <div>
                                <label htmlFor="endHour">Hora de fin:</label>
                            </div>
                        
                            <div>
                                <input
                                    type="time"
                                    className="form-control form-control-solid"
                                    id="endHour"
                                    name="endHour"
                                    value={formDataManual.endHour}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                    </div>
                </div> */}

                <h3 className="mt-6 mb-3">IScore</h3>
                <span className="text-muted">Selecciona qué valor tendrá esta campaña para promediar el iscore</span>

                <div className="mt-4">
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={formDataManual.iscore}
                        className="form-range"
                        name="iscore"
                        onChange={handleInputChange}
                    />
                </div>

                {alertInfo.text && (
                    <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                        <div>{alertInfo.text}</div>
                    </div>
                )}

                <button type="submit" className='mt-8 btn btn-primary btn-sm' onClick={handleFormSubmit}>Enviar</button>
            </div>
        </>
    )
}

export { ManualTabForm };