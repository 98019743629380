// Importamos hooks de react que usaremos para este componente
import {FC, useContext, useState} from 'react'
// Importamos helpers de metronic
import {KTIcon} from '../../../../_metronic/helpers'
// Importamos componentes Modal, Overlaytrigger y Tooltip de la libreria de bootstrap para react
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el componente ModalQrCampaign
import {ModalQrCampaign} from '../modals/ModalQrCampaign'
// Importamos el componente ModalEditCampaign
import {ModalEditCampaign} from '../modals/ModalEditCampaign'
// Importamos el componente CampaignsHistoryTable
import { CampaignsHistoryTable } from '../tables/CampaignsHistoryTable'

// ---------------------------------------------------------------------------------------

/* Importamos la funcion getFileLogoForCampaign para obtener el logo del canal 
correspondiente a cada tipo de campaña */
import {getFileLogoForCampaign, sessionExpired} from '../../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de campañas que usaremos para este componente
import {WabData, Horario, Channel} from '../interfaces/campaignsTypes'

// Importamos constante
import {BucketChannelLogosURL} from '../../../utils/constants/_Constants'

import {
  FormDataEditGral,
  FormDataEditConnect,
  FormDataEditNotify,
} from '../interfaces/campaignsTypes'
import {AlertInfo} from '../../../utils/interfaces/_InterfacesTypes'
import {UserContext} from '../../../app-context/user-context/UserContext'
import {campaignStatusDeactivate} from '../services/_CampaignsDeactivate'
import {campaignStatusActivate} from '../services/_CampaignsActivate'

//Definimos tipado de las propiedades (props)
type Props = {
  productId: string
  wab_dataCampaign: Array<WabData>
  horarioCampaign: Array<Horario>
  channelCampaign: Array<Channel>
  ChannelCamChannel: string
  channelTypeCampaign: string
  channelBuCampaign: string
  channelCrmIdCampaign: string
  accessTokenCampaign: string
  pro_idCampaign: string
  cli_idCampaign: string
  cam_idCampaign: string
  crm_idCampaign: string
  cam_statusCampaign: boolean
  iscoreCampaign: number
  nameCampaign: string
  pocCampaign: string
  notifyCampaign: []
  callCampaign: string
  qualityCampaign: boolean
  manualCampaign: boolean
  duplicateCampaign: number
  dupdaysCampaign: number
  cam_roleCampaign: string
  dcemCampaign: string
  qrCampaign: string
  // logoCampaign: string
  welcome_recCampaign: string
  mail_templateCampaign: string
  seller_mailtemplateCampaign: string
  crmCampaign: string
  mailchimpCampaign: number
  cam_menuCampaign: string
  sms_messageCampaign: boolean
  sms_templateCampaign: boolean
  super_duperCampaign: boolean
  anti_floodCampaign: boolean
  grade_optionsCampaign: {[key: string]: string}
  date_addCampaign: string
  date_updatedCampaign: string
  date_downCampaign: string
  b2b_botCampaign: string
  getCampaignsRegisters: () => void
}

//Iniciamos funcion ára construir componente CardCampaigns
export const CardCampaigns: FC<Props> = ({
  productId,
  wab_dataCampaign,
  horarioCampaign,
  channelCampaign,
  ChannelCamChannel,
  channelTypeCampaign,
  channelBuCampaign,
  channelCrmIdCampaign,
  accessTokenCampaign,
  pro_idCampaign,
  cli_idCampaign,
  cam_idCampaign,
  crm_idCampaign,
  cam_statusCampaign,
  iscoreCampaign,
  nameCampaign,
  pocCampaign,
  notifyCampaign,
  callCampaign,
  qualityCampaign,
  manualCampaign,
  duplicateCampaign,
  dupdaysCampaign,
  cam_roleCampaign,
  dcemCampaign,
  qrCampaign,
  // logoCampaign,
  welcome_recCampaign,
  mail_templateCampaign,
  seller_mailtemplateCampaign,
  crmCampaign,
  mailchimpCampaign,
  cam_menuCampaign,
  sms_messageCampaign,
  sms_templateCampaign,
  super_duperCampaign,
  anti_floodCampaign,
  grade_optionsCampaign,
  date_addCampaign,
  date_updatedCampaign,
  date_downCampaign,
  b2b_botCampaign,
  getCampaignsRegisters,
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  console.log(horarioCampaign[0].dias)
  console.log(horarioCampaign[0].start_hour)
  console.log(horarioCampaign[0].end_hour)
  console.log(horarioCampaign[0].shift)

  //Variables desde el usercontext
  const {token} = useContext(UserContext)
  const userToken = token

  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
  const [switchOn, setSwitchOn] = useState(cam_statusCampaign)

  const TooltipCustom = (props) => <Tooltip {...props}>Editar campaña</Tooltip>
  const TooltipCustom1 = (props) => <Tooltip {...props}>Historial de cambios</Tooltip>

  const initialFormDataEditGral: FormDataEditGral = {
    status: true,
    quality: true,
    cam_role: '',
    start_hour: '',
    end_hour: '',
    days: [],
    shift: '',
    roleId: '',
  }

  const initialFormDataEditConnect: FormDataEditConnect = {
    mail_template: '',
    wab_bot: '',
    crm_id: '',
    grade_options: {
      '1': 'No Localizable',
      '2': 'No Interesado',
      '3': 'Interés bajo',
      '4': 'Interés Medio',
      '5': 'Alto Interés',
    },
  }

  const initialFormDataEditNotify: FormDataEditNotify = {
    notify: [],
    call: '',
    cam_manuals: true,
  }

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const [formDataEditGral, setFormDataEditGral] =
    useState<FormDataEditGral>(initialFormDataEditGral)
  const [formDataEditConnect, setFormDataEditConnect] = useState<FormDataEditConnect>(
    initialFormDataEditConnect
  )
  const [formDataEditNotify, setFormDataEditNotify] =
    useState<FormDataEditNotify>(initialFormDataEditNotify)
  //Variables para manejo de estado de mostrar el modal de respuesta al desactivar campañas
  const [modalResponseVisible, setModalResponseVisible] = useState(false)
  //Variables para manejo del cuerpo del modal al cambiar el status de la campaña
  const [body, setBody] = useState<any>()
  const resetFormEdit = () => {
    setFormDataEditGral(initialFormDataEditGral)
    setFormDataEditConnect(initialFormDataEditConnect)
    setFormDataEditNotify(initialFormDataEditNotify)
  }

  //Constante para manejo de estado del modal de historial
  const [showModalHistory, setShowModalHistory] = useState<boolean>(false)

  //Funcion para abrir el modal de historial
  const openModalHistory = () => {
    setShowModalHistory(true);
  }

  const openModalEdit = () => {
    setShowModalEdit(true)

    setFormDataEditGral({
      ...formDataEditGral,
      status: cam_statusCampaign,
      quality: qualityCampaign,
      cam_role: cam_roleCampaign,
      start_hour: horarioCampaign[0].start_hour,
      end_hour: horarioCampaign[0].end_hour,
      days: horarioCampaign[0].dias,
      shift: horarioCampaign[0].shift,
    })

    setFormDataEditConnect({
      ...formDataEditConnect,
      mail_template: mail_templateCampaign,
      wab_bot: wab_dataCampaign[0].wab_bot,
      crm_id: crm_idCampaign,
      grade_options: grade_optionsCampaign,
    })

    //SETEO
    setFormDataEditNotify({
      ...formDataEditNotify,
      notify: notifyCampaign,
      call: callCampaign,
      cam_manuals: manualCampaign,
    })
  }

  const closeModalEdit = () => {
    setShowModalEdit(false)
    resetFormEdit()
  }

  //Funcion para cerrar el modal de historial
  const closeModalHistory = () => {
    setShowModalHistory(false)
  }

  const modalBodyOn = (
    <div className='alert alert-dismissible bg-light-warning border border-warning  mb-2'>
      <div className='d-flex flex-column flex-sm-row p-5'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-exclamation-triangle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
          </svg>
        </span>
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-1'>Desactivación de campaña</h5>
          <span className='text-dark'>
            <p>¿Estás seguro de desactivar la campaña {nameCampaign}?</p>
          </span>
        </div>
      </div>
    </div>
  )

  const modalBodyOff = (
    <div className='alert alert-dismissible bg-light-warning border border-warning  mb-2'>
      <div className='d-flex flex-column flex-sm-row p-5'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-exclamation-triangle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
          </svg>
        </span>
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-1'>Activación de campaña</h5>
          <span className='text-dark'>
            <p>¿Estás seguro de querer activar la caampaña {nameCampaign}?</p>
          </span>
        </div>
      </div>
    </div>
  )

  //Función para cerrar el modal de respuesta al intentar dar de baja una campaña
  const closeModal = () => {
    setModalResponseVisible(false)
  }

  //Esta función solo es para abrir el modal con info del cambio de status de la campaña
  const handleCheckboxChange = async (cam_idCampaign: string) => {
    setModalResponseVisible(true)

    if (cam_statusCampaign) {
      setBody(modalBodyOn)
    } else {
      setBody(modalBodyOff)
    }
  }

  //Aqui se debe disparar la funcion que hara el cambio de estatus de la marca
  const handleChangeCampaignStatus = async (cam_idCampaign: string) => {
    console.log('Se presionó esta wea')
    try {
      let response
      let petitionType
      if (cam_statusCampaign) {
        response = await campaignStatusDeactivate(cam_idCampaign, userToken)
        petitionType = 'CampaignStatusDeactivate'
      } else {
        response = await campaignStatusActivate(cam_idCampaign, userToken)
        petitionType = 'CampaignStatusActivate'
      }

      const {data, code, msg} = response

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      }

      if (code === 202 && petitionType === 'CampaignStatusDeactivate') {
        setAlertInfo({
          text: '¡La campaña se desactivó correctamente!',
          variant: 'success',
        })
        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })

          console.log('Se aplicó el timeout')
          setSwitchOn(false)
          getCampaignsRegisters()
          closeModal()
          return
        }, 3000)

        if (code === 202 && petitionType === 'CampaignStatusActivate') {
          setAlertInfo({
            text: '¡La campaña se desactivó correctamente!',
            variant: 'success',
          })

          setTimeout(() => {
            setAlertInfo({
              text: '',
              variant: '',
            })

            console.log('Se aplicó el timeout')
            setSwitchOn(false)
            getCampaignsRegisters()
            closeModal()
            return
          }, 3000)
        }
      }

      if (code === 202 && petitionType === 'CampaignStatusActivate') {
        setAlertInfo({
          text: '¡La campaña se activó correctamente!',
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          setSwitchOn(true)
          getCampaignsRegisters()
          closeModal()
        }, 3000)
        return
      }
    } catch (error) {
      setAlertInfo({
        text: `${error}`,
        variant: 'danger',
      })
      setTimeout(() => {
        setAlertInfo({
          text: '',
          variant: '',
        })
      }, 3000)
      throw error
    }
  }

  return (
    <div className='card h-100 border-2 border-gray-200 border-hover'>
      <div className='card-header border-0 p-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-45px w-45px bg-light me-5'>
            <img
              src={BucketChannelLogosURL + getFileLogoForCampaign(ChannelCamChannel)}
              alt={getFileLogoForCampaign(ChannelCamChannel)}
              className='p-1'
            />
          </div>
          <h2 className='fs-2 fw-bolder'>{nameCampaign}</h2>
        </div>
        <div className='card-toolbar m-0'>
          <OverlayTrigger placement='top' overlay={TooltipCustom}>
            <button
              type='button'
              className='btn btn-clean btn-lg btn-icon btn-active-light-primary me-n3'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_edit_campaign'
              onClick={openModalEdit}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </button>
          </OverlayTrigger>

          <OverlayTrigger placement='top' overlay={TooltipCustom1}>
            <button
              type='button'
              className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
              onClick={openModalHistory}
            >
              <KTIcon iconName='time' className='fs-1' />
            </button>
          </OverlayTrigger>

          <Modal
            show={showModalHistory}
            onhide={closeModalHistory}
            scrollable
            className='modal-xl'
          >
            <Modal.Header closeButton onClick={closeModalHistory}>
              <Modal.Title>
                <h1>Historial de cambios</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CampaignsHistoryTable nameCampaign={nameCampaign} cam_idCampaign={cam_idCampaign}/>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-sm btn-secondary'
                type='button'
                onClick={() => {
                  closeModalHistory()
                }}
              >
                cerrar
              </button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModalEdit} onHide={closeModalEdit} scrollable className='modal-lg'>
            <Modal.Header>
              <Modal.Title>
                <h1>{intl.formatMessage({id: 'CAMPAIGNS.MODAL_EDIT.TITLE'})}</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalEditCampaign
                productId={productId}
                formDataEditGral={formDataEditGral}
                setFormDataEditGral={setFormDataEditGral}
                formDataEditConnect={formDataEditConnect}
                setFormDataEditConnect={setFormDataEditConnect}
                formDataEditNotify={formDataEditNotify}
                setFormDataEditNotify={setFormDataEditNotify}
                channelBuCampaign={channelBuCampaign}
                nameCampaign={nameCampaign}
                cam_idCampaign={cam_idCampaign}
                closeModalEdit={closeModalEdit}
                getCampaignsRegisters={getCampaignsRegisters}
              />
            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-sm btn-secondary' type='button' onClick={closeModalEdit}>
                {intl.formatMessage({id: 'CAMPAIGNS.MODAL_EDIT.RESET_BUTTON'})}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='card-body d-flex flex-column p-9'>
        <div className='d-flex flex-wrap mb-5'>
          {cam_statusCampaign === true ? (
            <span className='badge badge-light-success fw-bolder fs-6 px-2 py-1 me-2'>Activo</span>
          ) : (
            <span className='badge badge-light-danger fw-bolder fs-6 px-2 py-1 me-2'>Inactivo</span>
          )}
          <span className='badge badge-light-primary fw-bolder fs-6 px-2 py-1 me-2'>
            {cam_idCampaign}
          </span>
          <span className='badge badge-light-primary fw-bolder fs-6 px-2 py-1 me-2'>
            {channelBuCampaign}
          </span>
          <span className='badge badge-light-primary fw-bolder fs-6 px-2 py-1 me-2'>
            {ChannelCamChannel}
          </span>
        </div>

        <div className='d-flex'>
          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 me-3 mb-3'>
            <div className='fs-6 fw-bolder'>{date_addCampaign}</div>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({id: 'CAMPAIGNS.CARD.CREATION_DATE'})}
            </div>
          </div>

          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder'>1000</div>
            <div className='fw-bold text-gray-400'>Leads</div>
          </div>
        </div>

        <div>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder'>{accessTokenCampaign}</div>
            <div className='fw-bold text-gray-400'>Access token</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder'>{pocCampaign}</div>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({id: 'CAMPAIGNS.CARD.PHONE_NUMBER'})}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='card-footer d-flex justify-content-end'>
        <div 
          className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
          key='poo'
        >
          <label className='form-check-label' key={cam_idCampaign}>
          {cam_statusCampaign === true
            ? intl.formatMessage({id: 'CAMPAIGNS.TITLE.FILTER_ACTIVE'})
            : intl.formatMessage({id: 'CAMPAIGNS.TITLE.FILTER_INACTIVE'})}
          </label>
          &nbsp;&nbsp;&nbsp;
          <input
              className='form-check-input'
              type='checkbox'
              id={`campaign_${cam_idCampaign}`}
              checked={switchOn}
              onChange={() => handleCheckboxChange(cam_idCampaign)}
            />

        </div>
        {channelTypeCampaign === 'Whatsapp' ? (
          <>
            <button
              type='button'
              className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_qr_campaign'
            >
              <i className='bi bi-qr-code fs-2 mr-4 text-info'></i>
            </button>

            <div className='modal fade' tabIndex={-1} id='kt_modal_qr_campaign'>
              <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 className='modal-title'>QR de la campaña</h4>
                    <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div>
                  </div>
                  <div className='modal-body' style={{maxHeight: '430px', overflow: 'auto'}}>
                    <ModalQrCampaign />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <Modal show={modalResponseVisible} onHide={closeModal} scrollable className='modal-default'>
        <Modal.Header closeButton onClick={closeModal}>
          <Modal.Title>Cambiar estatus de campaña</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        {alertInfo.text && (
          <div className='px-20'>
            <div
              className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
              role='alert'
            >
              <div>{alertInfo.text}</div>
            </div>
          </div>
        )}
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              handleChangeCampaignStatus(cam_idCampaign)
            }}
          >
            Aceptar
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              closeModal()
            }}
          >
            {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.RESET_BUTTON'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
