import React, { useContext, useEffect, useState } from "react"

import { WabaBot, WabaTemplate } from "../../../../utils/interfaces/_InterfacesTypes"
import { UserContext } from "../../../../app-context/user-context/UserContext"
import { LoadingAnimation } from "../../../../utils/components/loading/LoadingAnimation"
import { wabaTemplatesListByProduct } from "../../../../utils/services/_WabaTemplatesApi"

export const WhatsappTabForm = ({ productId }) => {

    //Algunas variables desde el usercontext
    const { user, token, updateToken } = useContext(UserContext);
    const clientId = user.cli_id;
    const userToken = token;

    const initialWabaTemplatesList: WabaTemplate = {
        templates: [
            {
                id_content: '',
                friendly_name: ''
            }
        ]
    }

    const [wabaTemplatesList, setWabaTemplatesList] = useState<WabaTemplate>(initialWabaTemplatesList)

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [valueWAB, setValueWAB] = useState<string>('HX2df10bb33831beaf776faa7dcdc8c4c5')

    const [isWabaBodyVisible, setIsWabaBodyVisible] = useState<boolean>(true)
    const [mainMessage, setMainMessage] = useState<string>('¡*Hola {{1}}*! ¡Qué bueno saber que estás interesado en *{{2}}*! Hemos recibido tu solicitud a través de *{{4}}* y ya te hemos puesto en contacto con {{3}}, uno de nuestros Asesores Comerciales quién está para ayudarte. Nuestro asesor se pondrá en contacto contigo pronto para brindarte toda la información que necesites y responder a tus preguntas. Mientras tanto, si lo prefieres, puedes conocer más de {{2}} a través de estas opciones:')
    const [qualityMessage, setQualityMessage] = useState<string>('¡Hola *{{1}}* !En días anteriores solicitaste información acerca de *{{2}}* por lo que nos gustaría saber si se puso en contacto contigo el Asesor asignado y te respondió todas tus dudas de manera correcta:')

    const getWabaTemplates = async (): Promise<void> => {
        try {
            const { data: jsonData, code, msg } = await wabaTemplatesListByProduct(userToken, productId)

            const wabaTemplatesReceived = jsonData.templates
            const newUserToken: string = jsonData.token

            updateToken(newUserToken)

            console.log(wabaTemplatesReceived)

            setWabaTemplatesList({
                templates: wabaTemplatesReceived
            })

        } catch (error) {
            console.error('Error obteniendo los waba templates: ', error)
        }

        // const wabaTemplatesListReceived: WabaTemplate = {
        //     client_id: '',
        //     wab_templates: [
        //         {
        //             wab_template_id: 'WTMP-API-001',
        //             wab_template_name: 'DragonCEM DaaD',
        //             wab_template_number: '',
        //             wab_template_description: '',
        //         },
        //         {
        //             wab_template_id: 'WTMP-API-002',
        //             wab_template_name: 'DragonCEM Caver',
        //             wab_template_number: '',
        //             wab_template_description: '',
        //         },
        //         {
        //             wab_template_id: 'WTMP-API-003',
        //             wab_template_name: 'DragonCEM Brasaas',
        //             wab_template_number: '',
        //             wab_template_description: '',
        //         }
        //     ]

        // }

        // setWabaTemplatesList(wabaTemplatesListReceived)
    }

    const getBodyWabaTemplate = (idWaba: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        setIsWabaBodyVisible(true)
        setIsLoading(true)
        const { name, value, type } = idWaba.target;
        console.log('id del waba template: ', value)
        console.log('tipo', type)

        setValueWAB(value)
        setTimeout(() => {
            if (value === '') {
                setIsWabaBodyVisible(false)
            } else if (value === 'HX2df10bb33831beaf776faa7dcdc8c4c5') {
                setMainMessage('¡*Hola {{1}}*! ¡Qué bueno saber que estás interesado en *{{2}}*! Hemos recibido tu solicitud a través de *{{4}}* y ya te hemos puesto en contacto con {{3}}, uno de nuestros Asesores Comerciales quién está para ayudarte. Nuestro asesor se pondrá en contacto contigo pronto para brindarte toda la información que necesites y responder a tus preguntas. Mientras tanto, si lo prefieres, puedes conocer más de {{2}} a través de estas opciones:')
                setQualityMessage('¡Hola *{{1}}* !En días anteriores solicitaste información acerca de *{{2}}* por lo que nos gustaría saber si se puso en contacto contigo el Asesor asignado y te respondió todas tus dudas de manera correcta:')
            } else {
                setMainMessage('No se encontró mensaje principal')
                setQualityMessage('No se encontro mensaje quality')
            }

            setIsLoading(false)

        }, 1000);

    }

    useEffect(() => {
        getWabaTemplates()
        // getBodyWabaTemplate()
        setTimeout(() => {
            setIsLoading(false)
        }, 3000);
    }, [])

    return (
        <>
            <div className="d-flex mb-6">
                <label className="col-3">Configuración de mensajes WhatsApp: </label>
                <select className="form-select form-select-solid" name="wabmsgconfig" value={valueWAB} id="wabmsgconfig" onChange={(e) => {
                    getBodyWabaTemplate(e)
                }}>
                    {/* <option value="General DragonCEM">General DragonCEM</option>
                <option value="DragonCEM producto">DragonCEM producto</option> */}
                    {wabaTemplatesList.templates.map((template, index) => (
                        <option key={index} id={template.id_content} value={template.id_content}>
                            {template.friendly_name}
                        </option>
                    ))}
                </select>
            </div>

            {isWabaBodyVisible && (

                <>

                    {isLoading && (
                        <LoadingAnimation alignment='center' label='Obteniendo información del template...' size='sm' />
                    )}

                    {!isLoading && (
                        <>
                            <div className="d-flex mb-6">
                                <label className="col-3">Mensaje principal:</label>
                                <textarea
                                    id="mainmsg"
                                    className="form-control form-control-solid text-muted"
                                    value={mainMessage}
                                    readOnly
                                    rows={4}
                                >
                                </textarea>
                            </div>

                            <div className="d-flex mb-6">
                                <label className="col-3">Mensaje quality:</label>
                                <textarea
                                    id="qamssg"
                                    className="form-control form-control-solid text-muted"
                                    value={qualityMessage}
                                    readOnly
                                    rows={4}
                                >
                                </textarea>
                            </div>
                        </>
                    )}

                </>

            )}

            <div>
                <label className="mb-6 mt-6">Manejo de duplicados a nivel: </label>
                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="optionssss"
                        id="option1"
                        value="option1"
                        checked={true}
                    />
                    <label className="form-check-label" htmlFor="option1">
                        Campaña
                    </label>
                </div>

                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="optionssss"
                        id="option2"
                        value="option2"

                    />
                    <label className="form-check-label" htmlFor="option2">
                        Producto
                    </label>
                </div>

                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        name="optionssss"
                        id="option2"
                        value="option2"

                    />
                    <label className="form-check-label" htmlFor="option2">
                        Marca
                    </label>
                </div>
            </div>

            <div className="d-flex mb-6 mt-8">
                <label className="col-3">Días para detectar duplicados:</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    value={30}
                />
            </div>

            <div className="d-flex">
                <label className="mb-4">Detectar leads duplicados sin improtar la fecha:</label>&nbsp;&nbsp;&nbsp;
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={true}
                />
            </div>

            <div className="d-flex">
                <label className="mb-4">Iscore:</label>&nbsp;&nbsp;&nbsp;
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={true}
                />&nbsp;&nbsp;&nbsp;
                <span className="text-muted">Puntuación máxima 20</span>
            </div>
            <button type="submit" className='mt-8 btn btn-primary btn-sm'>Enviar</button>
        </>
    )
}

