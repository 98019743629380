// Importamos hooks de react que usaremos para este componente
import React, {useState, useEffect, useContext} from 'react'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos componente con la animacion de carga de registros
import {LoadingAnimation} from '../../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsListByClient para conexion con API de productos
import {productsListByClient} from '../../products/services/_ProductsListApi'
// Importamos la funcion rolesListByClient para conexion con API de roles
import {rolesListByClient} from '../../assigns/services/_RolesListApi'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de marcas que usaremos para este componente
import {ProductsForBrand} from '../interfaces/brandsTypes'
// Importamos las interfaces de Roles que ocuparemos en este componente
import {Role} from '../../assigns/interfaces/rolesTypes'

// ---------------------------------------------------------------------------------------

// Importamos la funcion sessionExpired para el vencimiento de sesion
import {sessionExpired} from '../../../utils/functions/_Functions'

// Iniciamos funcion para construir el componente ModalNewBrand y recibimos las props
export function ModalNewBrand({formData, setFormData, errors, setErrors}) {
  //Algunas variables desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  const [brandProductsList, setBrandProductsList] = useState<ProductsForBrand[]>([])
  const [rolesList, setRolesList] = useState<Role[]>([])

  const checkProductsListLenght = () => {
    //Si el arreglo tiene un tamaño igual a cero
    if (brandProductsList.length === 0) {
      //Mandamos mensaje de productos no existentes
      setInfoMessageLoading('No hay ningun producto para asignar')
      //Ocultamos animacion de carga
      setIsLoading(false)
      //Si el arreglo tiene un tamaño mayor a cero
    } else {
      //Solo ocultamos animacion de carga
      setIsLoading(false)
    }
  }

  // Importamos libreria para manejo de lenguaje
  const intl = useIntl()

  const getProductsRegisters = async () => {
    //Mostramos la animacion de carga
    setIsLoading(true)

    try {
      const {data: jsonData, code, msg} = await productsListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const productsListReceived = jsonData.data

        const userTokenNew = await jsonData.token

        // console.log('actualiza token productsList (brands): ', userTokenNew)
        updateToken(userTokenNew)

        // console.log({productsListReceived});

        const productsSwitches = productsListReceived.map((product: ProductsForBrand) => {
          return {
            pro_id: product.pro_id,
            name: product.name,
          }
        })

        setBrandProductsList(productsSwitches)
        /* Ejecutamos la funcion para verificar  
        el tamaño del arreglo de marcas */
        checkProductsListLenght()
      }
    } catch (error) {
      setInfoMessageLoading('Ocurrio un error al intentar obtener las productos de este cliente')
      setIsLoading(false)
      //  console.log('error de productos ', error);
    }
  }

  const getRolesRegisters = async () => {
    try {
      const {data: jsonData, code, msg} = await rolesListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('sesion caducada')
        return
      } else {
        const rolesListReceived = jsonData.assigns

        console.log(rolesListReceived)

        const userTokenNew = jsonData.token

        setRolesList(rolesListReceived)

        updateToken(userTokenNew)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProductsRegisters()
    getRolesRegisters()
  }, [])

  // Funcion para el manejo en la interaccion de los inputs
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, type, checked, value} = e.target

    if (name === 'brandName') {
      if (!formData.brandName) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          brandName: undefined,
        }))
      }
    }

    setFormData((prevData) => {
      if (type === 'checkbox') {
        const updatedProducts = checked
          ? [...prevData.brandProducts, value]
          : prevData.brandProducts.filter((productId) => productId !== value)

        const updatedFormData = {
          ...prevData,
          brandProducts: updatedProducts,
        }

        // console.log("Updated formData:", updatedFormData);

        return updatedFormData
      } else {
        const updatedFormData = {
          ...prevData,
          [name]: value,
        }

        // console.log("Updated formData:", updatedFormData);

        return updatedFormData
      }
    })
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target

    if (name === 'roleId') {
      if (!formData.roleId) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          roleId: undefined,
        }))
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      roleId: value,
    }))
  }

  return (
    <>
      <div className='px-7'>
        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>
              {intl.formatMessage({id: 'BRANDS.MODAL_NEW.INPUT_NAME'})}
            </label>
            <input
              required
              type='text'
              name='brandName'
              value={formData.brandName}
              onChange={handleInputChange}
              className={`form-control form-control-solid mb-3 mb-lg-0 ${errors.brandName && 'is-invalid'}`}
              placeholder={intl.formatMessage({id: 'BRANDS.MODAL_NEW.INPUT_NAME'})}
            />
            {errors.brandName && <div className='invalid-feedback'>{errors.brandName}</div>}
          </div>
        </div>

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold'>CRM</label>

            <input
              type='text'
              name='crm'
              value={formData.crm}
              onChange={handleInputChange}
              className='form-control form-control-solid mb-3 mb-lg-0 required'
              placeholder='CRM'
              required
            />
          </div>
        </div>

        <div className='mb-5'>
          <label className='fs-6 fw-bold required'>
            {intl.formatMessage({id: 'BRANDS.MODAL_NEW.LABEL_ACTIVEROL'})}
          </label>
          <select
            className={`form-select form-select-solid ${errors.roleId && 'is-invalid'}`}
            name='roleId'
            onChange={handleSelectChange}
            value={formData.roleId}
          >
            <option value=''>
              -- {intl.formatMessage({id: 'BRANDS.MODAL_NEW.SELECTROL_OPTION1'})} --
            </option>
            {rolesList.map((role, index) => (
              <option key={index} id={role.role_id} value={role.role_id}>
                {role.role_name}
              </option>
            ))}
          </select>
          {errors.roleId && <div className='invalid-feedback'>{errors.roleId}</div>}
          <span className='text-muted mt-4'>
            {intl.formatMessage({id: 'GLOBAL.INDICATIONS.SELECTROLE'})}
          </span>
        </div>

        <div className='mb-5'>
          <div className='alert alert-info d-flex align-items-center' role='alert'>
            <div>{intl.formatMessage({id: 'BRANDS.MODAL_NEW.MSG_ALERT_ASSIGNS'})}</div>
          </div>
        </div>

        <div className='mb-10'>
          <label className='fs-6 fw-bold mb-4'>
            {intl.formatMessage({id: 'BRANDS.MODAL_NEW.SWITCH_PRODUCTS'})}:
          </label>

          {/* Validaciones para determinar que elementos mostraremos en la pantalla */}

          {/* Si isLoading es igual a 'true' lo mostramos */}
          {isLoading && <LoadingAnimation alignment='center' label='Obteniendo productos...' size='lg' />}

          {/* Si isLoading es diferente de 'true' y el tamaño de brandProductsList
          es mayor a 0 iniciamos el ciclo para pintar la lista de productos con su respectivo switch */}
          {!isLoading &&
            brandProductsList.length > 0 &&
            brandProductsList.map((item: ProductsForBrand) => (
              <div
                className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
                key={item.pro_id}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  name={`product_${item.pro_id}`}
                  checked={formData.brandProducts.includes(item.pro_id)}
                  onChange={handleInputChange}
                  value={item.pro_id}
                />
                <label className='form-check-label'>{item.name}</label>
              </div>
            ))}

          {/* Si isLoading es diferente de 'true' y el tamaño de brandProductsList
          es igual a 0 mostramos el mensaje informativo */}
          {!isLoading && brandProductsList.length === 0 && <p>{infoMessageLoading}</p>}
        </div>
      </div>
    </>
  )
}
