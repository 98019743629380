import { LeadCountsItem } from "./aux-components/LeadCountsItems";
import { GradeReport, GradeCounts, IdLead } from "./interfaces/gradesReportTypes";
import { FC, useEffect, useState, useContext } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { LoadingAnimation } from "../../../../utils/components/loading/LoadingAnimation";
import { GradesReportMainChart } from "./charts/GradesReportMainChart";
import { campaignsListForProduct } from "../../../campaigns/services/_CampaignsListApi";
import { Product, Campaign } from "./interfaces/gradesReportTypes";
import { UserContext } from "../../../../app-context/user-context/UserContext";
import { sessionExpired, dateFormatterISO8601Variant, dateFormatterTextualEnTwo, setPageTitle } from "../../../../utils/functions/_Functions";
import { GradesReportComparativeChart } from "./charts/GradesReportComparativeChart";
import { DateRangePickerFull } from "../../../../utils/components/date-pickers/DateRangePickerFull";
import { ProductsByUserDropdown } from "../../../../utils/components/dropdowns/ProductsByUserDropdown";
import { CampaignsByProductDropdown } from "../../../../utils/components/dropdowns/CampaignsByProductDropdown";
import { gradesReportDataPetition } from "./services/_GradesReportApi";
import { DragonCem } from "../../../../utils/constants/_Constants";

const GradesReport: FC = () => {

    //Algunas variables desde el usercontext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId = user.cli_id
    const userToken = token

    const initialGradesReportData: GradeReport = {
        totalLeads: 0,
        totalGradesCount: 0,
        totalLeadsIdGradesCount: [],
        totalLeadsNoDuplicate: 0,
        totalLeadsNoDuplicateIds: [],
        totalUnratedLeads: 0,
        unratedLeads: [],
        totalMultipleGradesLeads: 0,
        multipleGradesLeads: [],
        gradeCounts: {}
    }

    // Valores iniciales para el rango de fechas
    const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30));
    const initialEndDate: Date = new Date()

    // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
    const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
    const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

    // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
    const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
    const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

    // Manejador para la disponibilidad del dateRangePicker
    const [dataRangeState, setDataRangeState] = useState<boolean>(true)

    // const initialGradesReportDataByCampaign: GradeReport = {
    //     qualifiedLeads: []
    // }

    //Constante para manejar el item con los registros de calificaciones
    const [gradesReportItemByProduct, setGradesReportItemByProduct] = useState<GradeReport>(initialGradesReportData)
    const [gradesReportItemCampaignOne, setGradesReportItemCampaignOne] = useState<GradeReport>(initialGradesReportData)
    const [gradesReportItemCampaignTwo, setGradesReportItemCampaignTwo] = useState<GradeReport>(initialGradesReportData)

    const [productsList, setProductsList] = useState<Product[]>([])
    const [campaignsList, setCampaignsList] = useState<Campaign[]>([])

    const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')
    const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')
    const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)

    const [selectedIdCampaign, setSelectedIdCampaign] = useState<string>('')
    const [selectedNameCampaign, setSelectedNameCampaign] = useState<string>('Todas las campañas')
    const [selectedIdCampaignOne, setSelectedIdCampaignOne] = useState<string>('');
    const [selectedNameCampaignOne, setSelectedNameCampaignOne] = useState<string>('');
    const [selectedIdCampaignTwo, setSelectedIdCampaignTwo] = useState<string>('');
    const [selectedNameCampaignTwo, setSelectedNameCampaignTwo] = useState<string>('');

    const [campaignDropdownState, setCampaignDropdownState] = useState<boolean>(true)
    const [comparativeCampaignDropdownState, setComparativeCampaignDropdownState] = useState<boolean>(true)

    //Constante para el manejo de estado de la animacion de carga
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingComparativeChart, setIsLoadingComparativeChart] = useState<boolean>(false);

    //Constante para el manejo de estado del mensaje post carga de registros
    const [infoMessageLoadingChart, setInfoMessageLoadingChart] = useState<string>('');
    const [infoMessageLoadingItem, setInfoMessageLoadingItems] = useState<string>('');
    const [infoMessageLoadingcomparative, setInfoMessageLoadingcomparative] = useState<string>("");

    const checkGradesCountsLength = () => {
        Object.keys(gradesReportItemByProduct.gradeCounts).length === 0 && setInfoMessageLoadingChart("No se encontro ningún registro de calificacion")
    }

    const checkGradesTotals = () => {
        gradesReportItemByProduct.totalLeads === 0 && setInfoMessageLoadingItems('No se encontro ningún registro de leads')
    }

    // const checkGradesListByCampLength = () => {
    //     gradesReportItemCampaignTwo.qualifiedLeads.length === 0 && setInfoMessageLoading("No se encontro ningún registro de calificaciones")
    // }

    //Funcion para obtener el registro de los conteos de calificaciones
    const getGradesRegistersByProduct = async (productId: string, campaign?: string, startDateNew?: string, endDateNew?: string) => {
        
        let campaignId: string;
        let startDateFinal: string;
        let endDateFinal: string;
        
        if (startDateNew && startDateNew !== ''){
            startDateFinal = startDateNew 
        }else{
            startDateFinal = startDate
        }

        if(endDateNew && endDateNew !== ''){
            endDateFinal = endDateNew
        }else{
            endDateFinal = endDate
        }

        if(campaign && campaign !== '0'){
            campaignId = campaign 
        }else{
            campaignId = ''
        }

        try {
            const {data: jsonData, code, msg} = await gradesReportDataPetition(userToken, clientId, productId, campaignId, startDateFinal, endDateFinal)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
            }else{

                const {
                    totalLeads, 
                    totalGradesCount,
                    totalLeadsIdGradesCount,
                    totalLeadsNoDuplicate,
                    totalLeadsNoDuplicateIds,
                    totalUnratedLeads,
                    unratedLeads,
                    totalMultipleGradesLeads,
                    multipleGradesLeads,
                    gradeCounts,
                    token: userTokenNew
                } = jsonData;

                updateToken(userTokenNew)

                setGradesReportItemByProduct({
                    totalLeads: totalLeads,
                    totalGradesCount: totalGradesCount,
                    totalLeadsIdGradesCount: totalLeadsIdGradesCount,
                    totalLeadsNoDuplicate: totalLeadsNoDuplicate,
                    totalLeadsNoDuplicateIds: totalLeadsNoDuplicateIds,
                    totalUnratedLeads: totalUnratedLeads,
                    unratedLeads: unratedLeads,
                    totalMultipleGradesLeads: totalMultipleGradesLeads,
                    multipleGradesLeads: multipleGradesLeads,
                    gradeCounts: gradeCounts
                });

                if(Object.keys(gradeCounts).length === 0){
                    setComparativeCampaignDropdownState(true)
                }else{
                    setComparativeCampaignDropdownState(false)
                }

                setIsLoading(false)
                checkGradesCountsLength()
                checkGradesTotals()

            }

        }catch(error){
            console.error('Hubo un error en la peticion: ', error)
            setIsLoading(false)
            setInfoMessageLoadingChart('Hubo error en la peticion')
            setInfoMessageLoadingItems('Hubo error en la peticion')
        }

    }

    const getGradesRegistersByCampaignOne = async (productId: string, campaign?: string, startDateNew?: string, endDateNew?: string) => {
        let campaignId: string;
        let startDateFinal: string;
        let endDateFinal: string;
        
        if (startDateNew && startDateNew !== ''){
            startDateFinal = startDateNew 
        }else{
            startDateFinal = startDate
        }

        if(endDateNew && endDateNew !== ''){
            endDateFinal = endDateNew
        }else{
            endDateFinal = endDate
        }

        if(campaign && campaign !== '0'){
            campaignId = campaign 
        }else{
            campaignId = ''
        }

        try {
            const {data: jsonData, code, msg} = await gradesReportDataPetition(userToken, clientId, productId, campaignId, startDateFinal, endDateFinal)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
            }else{

                const {
                    totalLeads, 
                    totalGradesCount,
                    totalLeadsIdGradesCount,
                    totalLeadsNoDuplicate,
                    totalLeadsNoDuplicateIds,
                    totalUnratedLeads,
                    unratedLeads,
                    totalMultipleGradesLeads,
                    multipleGradesLeads,
                    gradeCounts,
                    token: userTokenNew
                } = jsonData;

                updateToken(userTokenNew)

                setGradesReportItemCampaignOne({
                    totalLeads: totalLeads,
                    totalGradesCount: totalGradesCount,
                    totalLeadsIdGradesCount: totalLeadsIdGradesCount,
                    totalLeadsNoDuplicate: totalLeadsNoDuplicate,
                    totalLeadsNoDuplicateIds: totalLeadsNoDuplicateIds,
                    totalUnratedLeads: totalUnratedLeads,
                    unratedLeads: unratedLeads,
                    totalMultipleGradesLeads: totalMultipleGradesLeads,
                    multipleGradesLeads: multipleGradesLeads,
                    gradeCounts: gradeCounts
                });

                // if(Object.keys(gradeCounts).length === 0){
                //     setComparativeCampaignDropdownState(true)
                // }else{
                //     setComparativeCampaignDropdownState(false)
                // }

                setIsLoadingComparativeChart(false)
                checkGradesCountsLength()
                checkGradesTotals()

            }

        }catch(error){
            console.error('Hubo un error en la peticion: ', error)
            setIsLoadingComparativeChart(false)
            setInfoMessageLoadingChart('Hubo error en la peticion')
            setInfoMessageLoadingItems('Hubo error en la peticion')
        }
    }
    
    const getGradesRegistersByCampaignTwo = async (productId: string, campaign?: string, startDateNew?: string, endDateNew?: string) => {
        let campaignId: string;
        let startDateFinal: string;
        let endDateFinal: string;
        
        if (startDateNew && startDateNew !== ''){
            startDateFinal = startDateNew 
        }else{
            startDateFinal = startDate
        }

        if(endDateNew && endDateNew !== ''){
            endDateFinal = endDateNew
        }else{
            endDateFinal = endDate
        }

        if(campaign && campaign !== '0'){
            campaignId = campaign 
        }else{
            campaignId = ''
        }

        try {
            const {data: jsonData, code, msg} = await gradesReportDataPetition(userToken, clientId, productId, campaignId, startDateFinal, endDateFinal)

            if (code === 401 && msg === 'Token no valido') {
                sessionExpired('La sesion ha caducado')
            }else{

                const {
                    totalLeads, 
                    totalGradesCount,
                    totalLeadsIdGradesCount,
                    totalLeadsNoDuplicate,
                    totalLeadsNoDuplicateIds,
                    totalUnratedLeads,
                    unratedLeads,
                    totalMultipleGradesLeads,
                    multipleGradesLeads,
                    gradeCounts,
                    token: userTokenNew
                } = jsonData;

                updateToken(userTokenNew)

                setGradesReportItemCampaignTwo({
                    totalLeads: totalLeads,
                    totalGradesCount: totalGradesCount,
                    totalLeadsIdGradesCount: totalLeadsIdGradesCount,
                    totalLeadsNoDuplicate: totalLeadsNoDuplicate,
                    totalLeadsNoDuplicateIds: totalLeadsNoDuplicateIds,
                    totalUnratedLeads: totalUnratedLeads,
                    unratedLeads: unratedLeads,
                    totalMultipleGradesLeads: totalMultipleGradesLeads,
                    multipleGradesLeads: multipleGradesLeads,
                    gradeCounts: gradeCounts
                });

                // if(Object.keys(gradeCounts).length === 0){
                //     setComparativeCampaignDropdownState(true)
                // }else{
                //     setComparativeCampaignDropdownState(false)
                // }

                setIsLoadingComparativeChart(false)
                checkGradesCountsLength()
                checkGradesTotals()

            }

        }catch(error){
            console.error('Hubo un error en la peticion: ', error)
            setIsLoadingComparativeChart(false)
            setInfoMessageLoadingChart('Hubo error en la peticion')
            setInfoMessageLoadingItems('Hubo error en la peticion')
        }
    }

    const handleProductSelected = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        setIsLoading(true)
        setSelectedNameCampaign('Todas las campañas')
        setSelectedIdCampaign('0')
        setSelectedIdCampaignOne('')
        setSelectedIdCampaignTwo('')
        setSelectedNameCampaignOne('')
        setSelectedNameCampaignTwo('')
        setGradesReportItemCampaignOne(initialGradesReportData)
        setGradesReportItemCampaignTwo(initialGradesReportData)

        let productIdSelected: string = e.target.value;

        if (productIdSelected) {
            setDataRangeState(false)

        } else {
            setDataRangeState(true)

        }

        if (e.target instanceof HTMLSelectElement) {
            setSelectedNameProduct(e.target.options[e.target.selectedIndex].text)
        }

        setSelectedIdProduct(productIdSelected)
        getGradesRegistersByProduct(productIdSelected)

        if (productIdSelected !== '' && productIdSelected !== '0') {
            // getCampaignsByProduct(productIdSelected);
        }

    }

    const handleCampaignSelected = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        let campaignIdSelected = e.target.value;
        let camStep = e.target.name;
        let campNamerovitional: string

        if (e.target instanceof HTMLSelectElement) {

            campNamerovitional = e.target.options[e.target.selectedIndex].text;

            if (e.target.name === 'campaignOne') {
                setIsLoadingComparativeChart(true)
                setSelectedNameCampaignOne(e.target.options[e.target.selectedIndex].text)
                setSelectedIdCampaignOne(campaignIdSelected)
                getGradesRegistersByCampaignOne(selectedIdProduct, campaignIdSelected)
                // setGradesReportItemCampaignOne(initialGradesReportDataByCampaign)
            } else if (e.target.name === 'campaignTwo') {
                setIsLoadingComparativeChart(true)
                setSelectedNameCampaignTwo(e.target.options[e.target.selectedIndex].text)
                setSelectedIdCampaignTwo(campaignIdSelected)
                getGradesRegistersByCampaignTwo(selectedIdProduct, campaignIdSelected)
                // setGradesReportItemCampaignTwo(initialGradesReportDataByCampaign)
            }else{
                setIsLoading(true)
                setSelectedNameCampaign(e.target.options[e.target.selectedIndex].text)
                setSelectedIdCampaign(campaignIdSelected)
                getGradesRegistersByProduct(selectedIdProduct, campaignIdSelected)
            }

            // getGradesRegistersByCampaign(campaignIdSelected, camStep, campNamerovitional)

        }

    }

    // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
    const handleDates = (): void => {
        setIsLoading(true)
        setSelectedIdCampaignOne('')
        setSelectedIdCampaignTwo('')
        setSelectedNameCampaignOne('')
        setSelectedNameCampaignTwo('')
        setGradesReportItemCampaignOne(initialGradesReportData)
        setGradesReportItemCampaignTwo(initialGradesReportData)
        const startDateNew: string = (dateFormatterISO8601Variant(labelStartDate))
        const endDateNew: string = (dateFormatterISO8601Variant(labelEndDate))

        setStartDate(startDateNew)
        setEndDate(endDateNew)

        getGradesRegistersByProduct(selectedIdProduct, selectedIdCampaign, startDateNew, endDateNew)

    }

    useEffect(() => {
        setPageTitle(`Reporte de Calificaciones | ${DragonCem}`)
    }, [])

    return (
        <>
            <div className="container-fluid">
                {/* Begin: Titulo de pagina y dropdown de clientes compartidos */}
                <div className='row justify-content-center mb-2'>
                    <div className='col-md-12'>
                        <div className='d-flex flex-wrap flex-stack mb-6'>
                            <h1 className='my-2 d-flex flex-wrap flex-stack'>
                                Reporte de calificaciones
                            </h1>
                            <div className='d-flex flex-wrap flex-stack align-items-center my-2'>

                                <div className='w-300px me-3'>
                                    <ProductsByUserDropdown 
                                        updateToken={updateToken}
                                        clientId={clientId}
                                        userToken={userToken}
                                        sessionExpired={sessionExpired}
                                        productIdSelected={selectedIdProduct}
                                        handleProductChange={handleProductSelected}
                                        productsList={productsList}
                                        setProductsList={setProductsList}
                                        productsDropdownState={productsDropdownState}
                                        dropdownClass='form-select form-select-sm form-select-solid mb-2 '
                                        allProductsOption={false}
                                        onlyActiveProducts={true}
                                    />
                                </div>

                                <div className="w-300px me-3">
                                    <CampaignsByProductDropdown 
                                        updateToken={updateToken}
                                        clientId={clientId}
                                        userToken={userToken}
                                        productIdSelected={selectedIdProduct}
                                        selectedIdCampaign={selectedIdCampaign}
                                        selectedIdCampaignOne={selectedIdCampaignOne}
                                        selectedIdCampaignTwo={selectedIdCampaignTwo}
                                        campaignsList={campaignsList}
                                        setCampaignsList={setCampaignsList}
                                        campaignDropdownState={campaignDropdownState}
                                        setCampaignDropdownState={setCampaignDropdownState}
                                        comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                                        setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                                        dropdownClass="form-select form-select-solid form-select-sm"
                                        dropdownName={'campaignSelect'}
                                        onlyActiveCampaigns={true}
                                        handleCampaignSelected={handleCampaignSelected}
                                        iscomparative={false}
                                    />

                                </div>

                                <div className='d-flex'>
                                    <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-secondary me-1'
                                        disabled={dataRangeState}
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                    >
                                        <KTIcon iconName='calendar' className='fs-2' />
                                    </button>

                                    <button
                                        type='button'
                                        className='btn btn-sm btn-secondary disabled'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                    >
                                        {'Rango actual: ' + dateFormatterTextualEnTwo(labelStartDate) + '  -  ' + dateFormatterTextualEnTwo(labelEndDate)}
                                    </button>

                                    {/* Componente con el DateRangePicker */}
                                    <DateRangePickerFull
                                        initialStartDate={initialStartDate}
                                        initialEndDate={initialEndDate}
                                        months={2}
                                        handleDates={handleDates}
                                        setLabelStartDate={setLabelStartDate}
                                        setLabelEndDate={setLabelEndDate}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End: Titulo de pagina y dropdown de clientes compartidos */}
                <div className="row gx-2 mb-6">
                    <div className="col-xl-12">
                        <div className="card card-custom px-8 py-10 h-100">
                            <div className="row justify-content-center mb-2">
                                <div className="col-md-12">
                                    {selectedIdProduct && (
                                        <div className="d-flex mb-4 justify-content-center">
                                            <h5 className="card-label me-4 d-flex flex-wrap flex-stack ">
                                                <span className="text-dark text-sm font-weight-bolder mt-3">
                                                    Resumen de calificaciones:
                                                </span>&nbsp;&nbsp;
                                                <span className="text-muted mt-3"> {selectedNameProduct} - {selectedNameCampaign}</span>
                                            </h5>
                                        </div>
                                    )}

                                    {!selectedIdProduct && (
                                        <>
                                            <div
                                                className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                                role='alert'
                                            >
                                                <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                                            </div>
                                        </>
                                    )}

                                    {/* Si isLoading es igual a 'true' mostramos animacion de carga */}
                                    {isLoading && selectedIdProduct && <div className="py-6"><LoadingAnimation alignment="center" label="Obteniendo calificaciones a leads..." size='lg' /></div>}
                                    {/* Si isLoading es diferente a 'true' y el arreglo de conteo de calificaciones es mayor a '0' mostramos tarjetas de conteo */}
                                    {!isLoading && selectedIdProduct && gradesReportItemByProduct.totalLeads !== 0 && (
                                        <LeadCountsItem 
                                            gradesReportItemByProduct={gradesReportItemByProduct} 
                                            selectedIdProduct={selectedIdProduct}
                                            selectedIdCampaign={selectedIdCampaign}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    )}

                                    {/* Si isLoading es diferente a 'true' y el arreglo de conteo de calificaciones es igual a '0' mostramos mensaje informativo  */}
                                    {!isLoading && selectedIdProduct && gradesReportItemByProduct.totalLeads === 0 && (
                                        // <div className="py-6 d-flex justify-content-center"><p>{infoMessageLoading}</p></div>

                                        <div
                                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                            role='alert'
                                        >
                                            <span className='mb-1 text-center'>{infoMessageLoadingItem}</span>
                                        </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row gx-2 mb-6">
                    <div className="col-xl-12">
                        <div className="card card-custom px-8 py-10 h-100">
                            <div className="row-justify-content-center mb-2">
                                <div className="col-md-12">
                                    {selectedIdProduct && (
                                        <div className="d-flex flex-wrap flex-stack mb-4 justify-content-center">
                                            <h5 className="card-label me-4 d-flex flex-wrap flex-stack">
                                                <span className="text-dark text-sm font-weight-bolder mt-3">
                                                    Grafica de calificaciones:
                                                </span>&nbsp;&nbsp;
                                                <span className="text-muted mt-3"> {selectedNameProduct} - {selectedNameCampaign}</span>
                                            </h5>
                                        </div>
                                    )}

                                    {!selectedIdProduct && (
                                        <>
                                            <div
                                                className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                                role='alert'
                                            >
                                                <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                                            </div>
                                        </>
                                    )}

                                    {/* si isLoading es igual a true mostramos animacion de carga  */}
                                    {isLoading && selectedIdProduct && <div className="py-6"><LoadingAnimation alignment="center" label="Generando grafica..." size='lg' /></div>}

                                    {/* Si isLoading es diferente a true y el arreglo de calificaciones es mayor a '0' mostramos la grafica */}
                                    {!isLoading && selectedIdProduct && gradesReportItemByProduct.totalLeads !== 0 && Object.keys(gradesReportItemByProduct.gradeCounts).length > 0 && (
                                        <GradesReportMainChart 
                                            gradesReportItemByProduct={gradesReportItemByProduct} 
                                            selectedIdProduct={selectedIdProduct}
                                            selectedIdCampaign={selectedIdCampaign}
                                            startDate={startDate}
                                            endDate={endDate}    
                                        />
                                    )}

                                    {/* Si isLoading es diferente a true y el arreglo de calificaciones es mayor a '0' mostramos mensaje informativo */}
                                    {!isLoading && selectedIdProduct && Object.keys(gradesReportItemByProduct.gradeCounts).length === 0 &&(
                                        // <div className="py-6 d-flex justify-content-center"><p>{infoMessageLoading}</p></div>
                                        <div
                                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                            role='alert'
                                        >
                                            <span className='mb-1 text-center'>{infoMessageLoadingChart}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row gx-2 mb-6">
                    <div className="col-xl-12">
                        <div className="card card-custom px-8 py-6 h-100">
                            <div className='row justify-content-center mb-2'>
                                <div className='col-md-12'>
                                    <div className='d-flex flex-wrap flex-stack mb-6'>
                                        <h5 className='my-2 d-flex flex-wrap flex-stack '>
                                            Desempeño de campañas: &nbsp;
                                            <span className="text-muted mt-1">{selectedNameProduct}</span>
                                        </h5>
                                        <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                                            <div className='w-300px me-3'>

                                                <CampaignsByProductDropdown 
                                                    updateToken={updateToken}
                                                    clientId={clientId}
                                                    userToken={userToken}
                                                    productIdSelected={selectedIdProduct}
                                                    selectedIdCampaign={selectedIdCampaign}
                                                    selectedIdCampaignOne={selectedIdCampaignOne}
                                                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                                                    campaignsList={campaignsList}
                                                    setCampaignsList={setCampaignsList}
                                                    campaignDropdownState={campaignDropdownState}
                                                    setCampaignDropdownState={setCampaignDropdownState}
                                                    comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                                                    setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                                                    dropdownClass="form-select form-select-solid form-select-sm"
                                                    dropdownName={'campaignOne'}
                                                    onlyActiveCampaigns={true}
                                                    handleCampaignSelected={handleCampaignSelected}
                                                    iscomparative={true}
                                                />
                                            </div>

                                            <div className='w-300px'>

                                                <CampaignsByProductDropdown 
                                                    updateToken={updateToken}
                                                    clientId={clientId}
                                                    userToken={userToken}
                                                    productIdSelected={selectedIdProduct}
                                                    selectedIdCampaign={selectedIdCampaign}
                                                    selectedIdCampaignOne={selectedIdCampaignOne}
                                                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                                                    campaignsList={campaignsList}
                                                    setCampaignsList={setCampaignsList}
                                                    campaignDropdownState={campaignDropdownState}
                                                    setCampaignDropdownState={setCampaignDropdownState}
                                                    comparativeCampaignDropdownState={comparativeCampaignDropdownState}
                                                    setComparativeCampaignDropdownState={setComparativeCampaignDropdownState}
                                                    dropdownClass="form-select form-select-solid form-select-sm"
                                                    dropdownName={'campaignTwo'}
                                                    onlyActiveCampaigns={true}
                                                    handleCampaignSelected={handleCampaignSelected}
                                                    iscomparative={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* si isLoading es igual a true mostramos animacion de carga  */}
                                    {isLoadingComparativeChart && selectedIdCampaignOne !== '' && 
                                        <div className="py-6">
                                            <LoadingAnimation alignment="center" label="Generando grafica..." size='lg' />
                                        </div>
                                    }

                                    {!isLoadingComparativeChart
                                        && selectedIdCampaignOne !== '' 
                                        // && gradesReportItemCampaignOne.totalLeads !== 0
                                        && Object.keys(gradesReportItemCampaignOne.gradeCounts).length > 0
                                        && selectedIdCampaignTwo !== ''
                                        // && gradesReportItemCampaignTwo.totalLeads !== 0
                                        && Object.keys(gradesReportItemCampaignTwo.gradeCounts).length > 0
                                        && (
                                            <>
                                               <GradesReportComparativeChart
                                                    gradesReportItemByProduct={gradesReportItemByProduct}
                                                    gradesReportItemCampaignOne={gradesReportItemCampaignOne}
                                                    gradesReportItemCampaignTwo={gradesReportItemCampaignTwo}
                                                    selectedNameCampaignOne={selectedNameCampaignOne}
                                                    selectedNameCampaignTwo={selectedNameCampaignTwo}
                                                    selectedIdCampaignOne={selectedIdCampaignOne}
                                                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                                                    selectedIdProduct={selectedIdProduct}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                             />

                                            </>
                                    )}

                                    {!isLoadingComparativeChart
                                        && selectedIdCampaignOne !== ''
                                        // && gradesReportItemCampaignOne.totalLeads !== 0
                                        && Object.keys(gradesReportItemCampaignOne.gradeCounts).length > 0
                                        && selectedIdCampaignTwo !== ''
                                        // && gradesReportItemCampaignTwo.totalLeads === 0
                                        && Object.keys(gradesReportItemCampaignTwo.gradeCounts).length === 0
                                        && (
                                            <>
                                                <GradesReportComparativeChart
                                                    gradesReportItemByProduct={gradesReportItemByProduct}
                                                    gradesReportItemCampaignOne={gradesReportItemCampaignOne}
                                                    gradesReportItemCampaignTwo={gradesReportItemCampaignTwo}
                                                    selectedNameCampaignOne={selectedNameCampaignOne}
                                                    selectedNameCampaignTwo={selectedNameCampaignTwo}
                                                    selectedIdCampaignOne={selectedIdCampaignOne}
                                                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                                                    selectedIdProduct={selectedIdProduct}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                             />

                                            </>
                                        )}

                                    {!isLoadingComparativeChart
                                        && selectedIdCampaignOne !== ''
                                        // && gradesReportItemCampaignOne.totalLeads !== 0
                                        && Object.keys(gradesReportItemCampaignOne.gradeCounts).length > 0
                                        && selectedIdCampaignTwo === ''
                                        // && gradesReportItemCampaignTwo.totalLeads === 0
                                        && Object.keys(gradesReportItemCampaignTwo.gradeCounts).length === 0
                                        && (
                                            <>
                                                <GradesReportComparativeChart
                                                    gradesReportItemByProduct={gradesReportItemByProduct}
                                                    gradesReportItemCampaignOne={gradesReportItemCampaignOne}
                                                    gradesReportItemCampaignTwo={gradesReportItemCampaignTwo}
                                                    selectedNameCampaignOne={selectedNameCampaignOne}
                                                    selectedNameCampaignTwo={selectedNameCampaignTwo}
                                                    selectedIdCampaignOne={selectedIdCampaignOne}
                                                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                                                    selectedIdProduct={selectedIdProduct}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                             />

                                            </>
                                        )}

                                    {!isLoadingComparativeChart
                                        && selectedIdCampaignOne !== ''
                                        // && gradesReportItemCampaignOne.totalLeads === 0
                                        && Object.keys(gradesReportItemCampaignOne.gradeCounts).length === 0
                                        && selectedIdCampaignTwo !== ''
                                        // && gradesReportItemCampaignTwo.totalLeads !== 0
                                        && Object.keys(gradesReportItemCampaignTwo.gradeCounts).length > 0
                                        && (
                                            <>
                                               <GradesReportComparativeChart
                                                    gradesReportItemByProduct={gradesReportItemByProduct}
                                                    gradesReportItemCampaignOne={gradesReportItemCampaignOne}
                                                    gradesReportItemCampaignTwo={gradesReportItemCampaignTwo}
                                                    selectedNameCampaignOne={selectedNameCampaignOne}
                                                    selectedNameCampaignTwo={selectedNameCampaignTwo}
                                                    selectedIdCampaignOne={selectedIdCampaignOne}
                                                    selectedIdCampaignTwo={selectedIdCampaignTwo}
                                                    selectedIdProduct={selectedIdProduct}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                             />

                                            </>
                                        )}

                                    {!isLoadingComparativeChart && 
                                      selectedIdCampaignOne !== '' &&
                                    //   gradesReportItemCampaignOne.totalLeads === 0 &&
                                      Object.keys(gradesReportItemCampaignOne.gradeCounts).length === 0  &&
                                      selectedIdCampaignTwo === '' && 
                                    //   gradesReportItemCampaignTwo.totalLeads === 0 && 
                                      Object.keys(gradesReportItemCampaignTwo.gradeCounts).length === 0  && (
                                        // <div className="py-6"><i>No hay datos en tu primer selección</i></div>
                                        <div
                                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                            role='alert'
                                        >
                                            <span className='mb-1 text-center'>No hay datos en tu primer selección</span>
                                        </div>
                                    )}

                                    {!isLoadingComparativeChart && 
                                      selectedIdCampaignOne !== '' &&
                                    //   gradesReportItemCampaignOne.totalLeads === 0 && 
                                      Object.keys(gradesReportItemCampaignOne.gradeCounts).length === 0  &&
                                      selectedIdCampaignTwo !== '' && 
                                    //   gradesReportItemCampaignTwo.totalLeads === 0 && 
                                      Object.keys(gradesReportItemCampaignTwo.gradeCounts).length === 0  && (
                                        // <div className="py-6"><i>No hay datos en ninguna campaña</i></div>
                                        <div
                                            className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                                            role='alert'
                                        >
                                            <span className='mb-1 text-center'>No hay datos en ninguna de las campañas</span>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export { GradesReport };