import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../../../app-context/user-context/UserContext";
import { Product, Campaign, CrmCountsReport } from "./interfaces/crmReportTypes";
import { campaignsListForProduct } from "../../../campaigns/services/_CampaignsListApi";
import { LoadingAnimation } from "../../../../utils/components/loading/LoadingAnimation";
import { LeadCountsItem } from "./aux-components/LeadCountsItems";
import { CrmReportLeadsChart } from "./charts/CrmReportLeadsChart";
import { CrmReportResponsesChart } from "./charts/CrmReportResponsesChart";
import { setPageTitle } from "../../../../utils/functions/_Functions";
import { KTIcon } from "../../../../../_metronic/helpers";
import { DragonCem } from "../../../../utils/constants/_Constants";
import { sessionExpired, dateFormatterISO8601Variant, dateFormatterTextualEnTwo } from "../../../../utils/functions/_Functions";
import { DateRangePickerFull } from "../../../../utils/components/date-pickers/DateRangePickerFull";
import { ProductsByUserDropdown } from "../../../../utils/components/dropdowns/ProductsByUserDropdown";

const CrmReport = () => {

  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token

  const initialCrmReportItem: CrmCountsReport = {
    totalLeadsReceived: 0,
    totalLeadsToCrm: 0,
    totalLeadsNoToCrm: 0,
    totalLeadsGoodResponse: 0,
    totalLeadsBadResponse: 0
  }

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30));
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  const [crmReportItem, setCrmReportItem] = useState<CrmCountsReport>(initialCrmReportItem)

  const [productsList, setProductsList] = useState<Product[]>([])
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([])

  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')
  const [productsDropdownState, setProductsDropdownState] = useState<boolean>(false)

  const [selectedIdCampaign, setSelectedIdCampaign] = useState<string>('0')
  const [selectedNameCampaign, setSelectedNameCampaign] = useState<string>('Todas las campañas')

  const [showCards, setShowCards] = useState<boolean>(false)

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

  const checkCountLeadsToCrm = () => {
    crmReportItem.totalLeadsToCrm === 0 && setInfoMessageLoading('Ningún lead se envio a crm')
  }

  //Funcion para obtener los conteos para el reporte
  const getCrmCounts = (productId: string, campaignId?: string) => {
    if (productId !== '') {
      setTimeout(() => {
        try {
          const newCrmCountsList: CrmCountsReport =
          {
            totalLeadsReceived: 6,
            totalLeadsToCrm: 4,
            totalLeadsNoToCrm: 2,
            totalLeadsGoodResponse: 3,
            totalLeadsBadResponse: 1
          }

          setCrmReportItem(newCrmCountsList)
          setIsLoading(false)
          setShowCards(true)
          checkCountLeadsToCrm()

        } catch (error) {
          console.log("Error obteniendo los conteos del reporte: ", error)
          setIsLoading(false)
          setInfoMessageLoading('Hubo un error en la petición')
        }
      }, 3000)
    }
  }

  const getCampaignsByProduct = async (productValue: string): Promise<void> => {
    try {
      const { data: jsonData, code, msg } = await campaignsListForProduct(userToken, productValue)

      const campaignsListReceived = jsonData.data

      const userTokenNew = jsonData.token

      updateToken(userTokenNew)

      const campaignItem = campaignsListReceived.map((campaign: Campaign) => {
        return {
          cam_id: campaign.cam_id,
          name: campaign.name,
        }
      })

      setCampaignsList(campaignItem)

    } catch (error) {
      console.log('Ocurrio un error obteniendo las campañas del producto: ', error)
    }
  }

  const handleProductOrCampaignSelected = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

    setIsLoading(true)

    let productIdSelected: string;
    let campaignIdSelected: string;

    if (e.target.name === 'product') {
      productIdSelected = e.target.value;

      if (productIdSelected) {
        setDataRangeState(false)

      } else {
        setDataRangeState(true)

      }

      console.log('interaccion productos')

      if (e.target instanceof HTMLSelectElement) {
        setSelectedNameProduct(e.target.options[e.target.selectedIndex].text)
      }

      setSelectedIdProduct(productIdSelected)
      getCrmCounts(productIdSelected)

      if (productIdSelected !== '' && productIdSelected !== '0') {
        getCampaignsByProduct(productIdSelected);
        setCampaignsList([])
        setSelectedIdCampaign('0')
        setSelectedNameCampaign('Todas las campañas')
      } else {
        setCampaignsList([])
        setSelectedIdCampaign('')
        setSelectedNameCampaign('')
      }

    } else if (e.target.name === 'campaign') {
      console.log('interaccion campañas')

      campaignIdSelected = e.target.value;

      if (e.target instanceof HTMLSelectElement) {
        setSelectedNameCampaign(e.target.options[e.target.selectedIndex].text)
      }

      setSelectedIdCampaign(campaignIdSelected)
      getCrmCounts(selectedIdProduct, campaignIdSelected)

    }

  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    const startDateNew: string = (dateFormatterISO8601Variant(labelStartDate))
    const endDateNew: string = (dateFormatterISO8601Variant(labelEndDate))

    setStartDate(startDateNew)
    setEndDate(endDateNew)

  }

  useEffect(() => {
    setPageTitle(`Reporte de CRM | ${DragonCem}`)
  }, [])

  return (
    <>
      <div className="container-fluid">
        {/* Begin: Titulo de pagina y dropdown de clientes compartidos */}
        <div className="row justify-content-center mb-2">
          <div className="col-md-12">
            <div className="d-flex flex-wrap flex-stack mb-6">
              <h1 className="my-2 d-flex flex-wrap flex-stack">
                {/* Reporte CRM <span className="d-flex flex-wrap flex-stack fs-4 text-gray-500 fw-bold ms-1">1 Abril 2024 a 30 Abril 2024</span> */}
                Reporte CRM
              </h1>
              <div className="d-flex flex-wrap flex-stack align-items-center my-2">

                <div className='w-300px me-3'>
                  {/* Componente con el dropdown de seleccion de producto */}
                  <ProductsByUserDropdown
                    updateToken={updateToken}
                    clientId={clientId}
                    userToken={userToken}
                    sessionExpired={sessionExpired}
                    productIdSelected={selectedIdProduct}
                    handleProductChange={handleProductOrCampaignSelected}
                    productsList={productsList}
                    setProductsList={setProductsList}
                    productsDropdownState={productsDropdownState}
                    dropdownClass='form-select form-select-sm form-select-solid mb-2 '
                    allProductsOption={true}
                    onlyActiveProducts={true}
                  />
                </div>

                <div className='w-300px me-3'>
                  <select
                    name='campaign'
                    data-control='selectCampaign'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-sm'
                    value={selectedIdCampaign}
                    onChange={(e) => {
                      handleProductOrCampaignSelected(e)
                    }}
                    disabled={selectedIdProduct === '' || selectedIdProduct === '0'}
                  >
                    <option value='' disabled>Selecciona una campaña</option>
                    <option value='0'>Todas las campañas</option>
                    {campaignsList.map((campaign: Campaign) => (
                      <option key={campaign.cam_id} value={campaign.cam_id}>{campaign.name}</option>
                    ))}
                  </select>
                </div>

                <div className='d-flex'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-secondary me-1'
                    disabled={dataRangeState}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTIcon iconName='calendar' className='fs-2' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-sm btn-secondary disabled'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    {'Rango actual: ' + dateFormatterTextualEnTwo(labelStartDate) + '  -  ' + dateFormatterTextualEnTwo(labelEndDate)}
                  </button>

                  {/* Componente con el DateRangePicker */}
                  <DateRangePickerFull
                    initialStartDate={initialStartDate}
                    initialEndDate={initialEndDate}
                    months={2}
                    handleDates={handleDates}
                    setLabelStartDate={setLabelStartDate}
                    setLabelEndDate={setLabelEndDate}
                  />

                </div>

              </div>
            </div>
          </div>
        </div>
        {/* End: Titulo de pagina y dropdown de clientes compartidos */}

        <div className="row gx-2 mb-6">
          <div className="col-xl-12">
            <div className="card card-custom px-8 py-10 h-100">
              <div className="row justify-content-center mb-2">
                <div className="col-md-12">
                  {selectedIdProduct && (
                    <div className="d-flex mb-4 justify-content-center">
                      <h5 className="card-label me-4 d-flex flex-wrap flex-stack">
                        <span className="text-dark text-sm font-weight-bolder mt-3">
                          Resumen de leads a CRM:
                        </span>&nbsp;&nbsp;
                        <span className="text-muted mt-3"> {selectedNameProduct} - {selectedNameCampaign}</span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                      </div>
                    </>
                  )}

                  {/* Si isLoading es igual a 'true' mostramos animacion de carga */}
                  {isLoading && selectedIdProduct && (<div className="py-6"><LoadingAnimation alignment="center" label="Obteniendo conteos de leads..." size='lg' /></div>)}
                  {/* Si isLoading es diferente a 'true' y el arreglo de conteo de calificaciones es mayor a '0' mostramos tarjetas de conteo */}
                  {!isLoading && selectedIdProduct && showCards && (
                    <LeadCountsItem crmReportItem={crmReportItem} />
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row gx-2 mb-6">
          <div className="col-xl-6">
            <div className="card card-custom px-8 py-10 h-100">
              <div className="row justify-content-center mb-2">
                <div className="col-md-12">
                  {selectedIdProduct && (
                    <div className="d-flex mb-4 justify-content-center">
                      <h5 className="card-label me-4 d-flex flex-wrap flex-stack ">
                        <span className="text-dark text-sm font-weight-bolder mt-3">
                          Resumen de leads:
                        </span>&nbsp;&nbsp;
                        <span className="text-muted mt-3"> {selectedNameProduct} - {selectedNameCampaign}</span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                      </div>
                    </>
                  )}

                  {/* si isLoading es igual a true mostramos animacion de carga  */}
                  {isLoading && selectedIdProduct && (<div className="py-6"><LoadingAnimation alignment="center" label="Generando grafica..." size='lg' /></div>)}

                  {!isLoading && selectedIdProduct && crmReportItem.totalLeadsToCrm > 0 && (
                    <CrmReportLeadsChart crmReportItem={crmReportItem} />
                  )}

                  {!isLoading && selectedIdProduct && crmReportItem.totalLeadsToCrm === 0 && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>No hay leads enviados a CRM</h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="card card-custom px-8 py-10 h-100">
              <div className="row justify-content-center mb-2">
                <div className="col-md-12">
                  {selectedIdProduct && (
                    <div className="d-flex mb-4 justify-content-center">
                      <h5 className="card-label me-4 d-flex flex-wrap flex-stack ">
                        <span className="text-dark text-sm font-weight-bolder mt-3">
                          Respuestas de CRM:
                        </span>&nbsp;&nbsp;
                        <span className="text-muted mt-3"> {selectedNameProduct} - {selectedNameCampaign}</span>
                      </h5>
                    </div>
                  )}

                  {!selectedIdProduct && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                      </div>
                    </>
                  )}

                  {/* si isLoading es igual a true mostramos animacion de carga  */}
                  {isLoading && selectedIdProduct && (<div className="py-6"><LoadingAnimation alignment="center" label="Generando grafica..." size='lg' /></div>)}

                  {!isLoading && selectedIdProduct && crmReportItem.totalLeadsToCrm > 0 && (
                    <CrmReportResponsesChart crmReportItem={crmReportItem} />
                  )}

                  {!isLoading && selectedIdProduct && crmReportItem.totalLeadsToCrm === 0 && (
                    <>
                      <div
                        className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                        role='alert'
                      >
                        <h5 className='mb-1 text-center'>No hay leads enviados a CRM</h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  );
};

export { CrmReport };