// Importamos hooks de react que usaremos para este componente
import { FC, useEffect, useState, useContext, ChangeEvent } from 'react'
// Importamos componentes de la libreria de bootstrap para react
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
// Importamos libreria para manejo de lenguaje
import { useIntl } from 'react-intl'
// Importamos funcion setPageTitle para el seteo de titulo de pagina actual
import { setPageTitle } from '../../utils/functions/_Functions'
// Importamos constante de titulo 'DragonCEM'
import { DragonCem } from '../../utils/constants/_Constants'
// Importamos componente para referencia de paginas
import { Link } from 'react-router-dom'
// Importamos componente para juego de iconos de metronic
import { KTIcon } from '../../../_metronic/helpers'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from '../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos componente LeadsMainTable para renderizar la tabla principal de leads
import { LeadsMainTable } from './tables/LeadsMainTable'
// Importamos componente para renderizar el modal de creacion de lead
import { ModalNewLead } from './modals/ModalNewLead'
// Importamos componente para exportacion de registros
import { DropdownExportBrands } from '../../../_metronic/partials/content/dropdown/DropdownExportBrands'
// Importamos componente LeadsMainChart
import { LeadsMainChart } from './charts/LeadsMainChart'
// Importamos componente global para la animacion de carga
import { LoadingAnimation } from '../../utils/components/loading/LoadingAnimation'
// Importamos componente global de selector de fechas
import { DateRangePickerFull } from '../../utils/components/date-pickers/DateRangePickerFull'
// Importamos componente para exportar registros
import { DropdownExport } from '../../../_metronic/partials/content/dropdown/DropdownExport'

// ---------------------------------------------------------------------------------------

// Importamos funcion con la peticion para obtener el listado de leads
import { leadsListByClient } from './services/_LeadsListApi'

// ---------------------------------------------------------------------------------------

// Importamos funcion para vencimiento de sesion y formateo de fechas a formato ISO 8601
import { sessionExpired, dateFormatterISO8601, dateFormatterTextualEnTwo, dateFormatterISO8601Variant, dateFormatterDateTimeExtended, dateFormatterTextualEn } from '../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos interfaces de leads que usaremos para este componente
import { Lead, LeadData } from './interfaces/leadsTypes'

// Iniciamos construccion del componente Leads
const Leads: FC = () => {

  // Valores iniciales para el rango de fechas
  const initialStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 30));
  const initialEndDate: Date = new Date()

  // Constantes que usaremos para manejar las variables para los labels que muestran la fecha
  const [labelStartDate, setLabelStartDate] = useState<Date>(initialStartDate)
  const [labelEndDate, setLabelEndDate] = useState<Date>(initialEndDate)

  // Constantes que usaremos para manejar las fechas que enviaremos a la peticion
  const [startDate, setStartDate] = useState<string>(dateFormatterISO8601Variant(initialStartDate))
  const [endDate, setEndDate] = useState<string>(dateFormatterISO8601Variant(initialEndDate))

  // Manejador para la disponibilidad del dateRangePicker
  const [dataRangeState, setDataRangeState] = useState<boolean>(true)

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Algunas constantes desde el userContext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  //constante para el manejo de estado del modal de creacion de lead manual
  const [showModalNewLead, setShowModalNewLead] = useState<boolean>(false)

  //Funcion para abrir el modal de creacion de lead manual
  const openModalLeadNew = () => {
    setShowModalNewLead(true)
  }

  //Funcion para cerrar el modal de creacion de lead manual
  const closeModalLeadNew = () => {
    setShowModalNewLead(false)
  }

  //Constante para guardar los datos de las fechas de los leads
  const [leadsDateData, setLeadsDateData] = useState<string[]>([])

  //Constante para guardar los datos de los leads originales
  const [leadsOriginalData, setLeadsOriginalData] = useState<number[]>([])

  //Constante para guardar los datos de los leads duplicados
  const [leadsDuplicateData, setLeadsDuplicateData] = useState<number[]>([])

  //Constante para el manejo de la lista de leads
  const [leadsList, setLeadsList] = useState<Lead[]>([])

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  // Definimos variables para la exportación de la data a formato hoja de calculos
  const [dataToExport, setDataToExport] = useState({});

  //Funcion para verificar el tamaño del listado de leads
  const checkLeadsListLength = () => {
    leadsList.length === 0 && setInfoMessageLoading('No se encontro ningún lead')
  }

  const calculateChartData = (leadsData: Lead[]) => {
    // Usar Set<string> para almacenar fechas únicas (solo yyyy-mm-dd)
    const uniqueDatesSet = new Set<string>(
      leadsData.map((lead: Lead) => lead.lead_date.split(' ')[0])
    );
    const uniqueDates: string[] = Array.from(uniqueDatesSet);

    // Inicializar arrays para contar leads originales y duplicados por fecha
    const originalLeadsCounts: number[] = Array(uniqueDates.length).fill(0);
    const duplicateLeadsCounts: number[] = Array(uniqueDates.length).fill(0);

    // Contar leads originales y duplicados por fecha
    leadsData.forEach((lead: Lead) => {
      const leadDateOnly = lead.lead_date.split(' ')[0]; // Extraer solo la parte de la fecha
      const dateIndex = uniqueDates.findIndex((date) => date === leadDateOnly);

      if (dateIndex !== -1) {
        if (lead.lead_original === '') {
          // Si lead_original es un string vacío, es un lead original
          originalLeadsCounts[dateIndex]++;
        } else {
          // Cualquier otro valor en leadOriginal indica un lead duplicado
          duplicateLeadsCounts[dateIndex]++;
        }
      }
    });

    // Construir los arrays finales para originalLeads y duplicateLeads
    const originalLeadsData: number[] = originalLeadsCounts.map((count) => count);
    const duplicateLeadsData: number[] = duplicateLeadsCounts.map((count) => count);

    // Preparar el array de fechas en el formato esperado
    const datesData: string[] = uniqueDates.map((date) => dateFormatterTextualEn(date));

    return { originalLeadsData, duplicateLeadsData, datesData };
  };

  //funcion para manejar el estado de la fecha de inicio
  // const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {

  //   const start: Date = new Date(e.target.value);
  //   const end: Date = new Date(endDate);

  //   if (end <= start) {
  //     alert('La fecha de fin debe ser mayor que la fecha de inicio.');
  //     return;
  //   }

  //   const differenceInDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
  //   if (differenceInDays > 31) {
  //     alert('El rango de fechas no puede ser mayor a 31 días.');
  //     return;
  //   }

  //   setStartDate(e.target.value)
  //   getLeadsByClient()
  // }

  //Funcion para manejar el estado de la fecha de fin
  // const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {

  //   const start = new Date(startDate);
  //   const end = new Date(e.target.value);

  //   if (end <= start) {
  //     alert('La fecha de fin debe ser mayor que la fecha de inicio.');
  //     return;
  //   }

  //   const differenceInDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
  //   if (differenceInDays > 31) {
  //     alert('El rango de fechas no puede ser mayor a 31 días.');
  //     return;
  //   }

  //   setEndDate(e.target.value)
  //   getLeadsByClient()

  // }

  //Funcion para conectar con el endpoint para el listado de leads
  const getLeadsByClient = async (): Promise<void> => {

    setIsLoading(true)

    const formattedStartDate: string = dateFormatterISO8601(startDate)
    const formattedEndDate: string = dateFormatterISO8601(endDate)

    console.log('Formatted Start Date:', formattedStartDate)
    console.log('Formatted End Date:', formattedEndDate)

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await leadsListByClient(userToken, startDate, endDate, clientId)

      if (code === 401 && msg === 'Token no valido') {

        sessionExpired('La sesion ha caducado')
        return

      } else {

        const leadsListReceived: Lead[] = jsonData.data

        const userTokenNew: string = jsonData.token

        updateToken(userTokenNew)

        console.log(leadsListReceived)

        const leadRow: Lead[] = leadsListReceived.map((lead: Lead) => {
          const leadData: LeadData = {
            name: lead.lead_data.name,
            lead_mail: lead.lead_data.lead_mail,
            lead_phone: lead.lead_data.lead_phone,
          }

          const formattedDate: string = dateFormatterDateTimeExtended(lead.lead_date)

          return {
            lead_date: formattedDate,
            lead_data: leadData,
            lead_id: lead.lead_id,
            seller_score: lead.seller_score,
            dcfs: lead.dcfs,
            mail_delivered: lead.mail_delivered,
            call_recording: lead.call_recording,
            wha_sent: lead.wha_sent,
            lead_qa: lead.lead_qa,
            crm_insert: lead.crm_insert,
            lead_comments: lead.lead_comments,
            seller_id: lead.seller_id,
            seller_name: lead.seller_name,
            pro_name: lead.pro_name,
            pro_id: lead.pro_id,
            cam_id: lead.cam_id,
            cam_name: lead.cam_name,
            medium: lead.medium,
            lead_endpoint: 'Zapier',
            leadGrade: lead.leadGrade,
            leadGradeComments: lead.leadGradeComments,
            leadIscore: lead.leadIscore,
            lead_original: '',
            leadUrl: lead.leadUrl,
            spamFlag: lead.spamFlag,
            roleName: lead.roleName,
            roleId: lead.roleId
          }
        })

        // Uso de la función para calcular los datos
        const { originalLeadsData, duplicateLeadsData, datesData } = calculateChartData(leadRow)

        setLeadsDateData(datesData)
        setLeadsOriginalData(originalLeadsData)
        setLeadsDuplicateData(duplicateLeadsData)

        console.log(datesData)
        console.log(originalLeadsData)
        console.log(duplicateLeadsData)

        setLeadsList(leadRow)
        setIsLoading(false)
        checkLeadsListLength()
      }
    } catch (error) {
      console.log('Error receiving leads list: ', error)
      setInfoMessageLoading('Ocurrio un error al obtener el listado de leads')
      setIsLoading(false)

    }
  }

  // Manejamos las acciones que queremos para cuando se interactue con las fechas del DateRange
  const handleDates = (): void => {
    const startDateNew: string = (dateFormatterISO8601Variant(labelStartDate))
    const endDateNew: string = (dateFormatterISO8601Variant(labelEndDate))

    setStartDate(startDateNew)
    setEndDate(endDateNew)

  }

  // useEffect(() => {
  //   const today = new Date()
  //   const fifteenDaysAgo = new Date(today)
  //   fifteenDaysAgo.setDate(today.getDate() - 15)
  //   const tomorrow = new Date(today)
  //   tomorrow.setDate(today.getDate() + 1)

  //   setStartDate(fifteenDaysAgo.toISOString().split('T')[0])
  //   setEndDate(tomorrow.toISOString().split('T')[0])
  // }, [])

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      getLeadsByClient()
    }
  }, [startDate, endDate])

  //Efecto para disparar las funciones iniciales
  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: 'LEADS.TITLE' })} | ${DragonCem}`)
    // getLeadsByClient()
  }, [])

  const handleSetExport = (data: any) => {
    const dataToExport = data//table.getRowModel().flatRows.map((row) => row.original);
    console.log(dataToExport)
    setDataToExport(dataToExport)
  }

  const renderTooltip = (props) => <Tooltip {...props}>Ir a Spam</Tooltip>

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>
                Leads
              </h1>
              <div className='d-flex align-items-center my-2'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  // disabled={dataRangeState}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTIcon iconName='calendar' className='fs-2'/>
                </button>

                <button
                  type='button'
                  className='btn btn-sm btn-secondary disabled me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  {'Rango actual: ' + dateFormatterTextualEnTwo(labelStartDate) + '  -  ' + dateFormatterTextualEnTwo(labelEndDate)}
                </button>

                {/* Componente con el DateRangePicker */}
                <DateRangePickerFull
                  initialStartDate={initialStartDate}
                  initialEndDate={initialEndDate}
                  months={2}
                  handleDates={handleDates}
                  setLabelStartDate={setLabelStartDate}
                  setLabelEndDate={setLabelEndDate}
                />

                {/* Boton para abrir el modal de creacion de lead manual */}
                <button
                  type='button'
                  className='btn btn-primary btn-sm me-3'
                  onClick={openModalLeadNew}
                >
                  Nuevo lead
                </button>

                {/* Para ir al apartado SPAM */}
                <OverlayTrigger placement='top' overlay={renderTooltip}>
                  <Link to='/lead-spam' className='btn btn-danger btn-sm me-3'>
                    SPAM
                  </Link>
                </OverlayTrigger>

                {/* Boton para desplegar dropdown de exportacion */}
                <div>
                  <DropdownExport
                    dataToExport={dataToExport}
                    title={'Leads'}
                  />
                </div>

              </div>
            </div>

            <div className='row gy-12 gx-xl-12 mb-6'>
              <div className='card col-xl-12 mb-3 py-6'>
                {isLoading && (
                  <div className='py-12'>
                    <LoadingAnimation alignment='center' label='Generando grafica de leads...' size='lg' />
                  </div>
                )}

                {!isLoading && leadsList.length > 0 && (
                  <LeadsMainChart
                    className='card-xxl-stretch mb-5 mb-xl-10'
                    datesData={leadsDateData}
                    originalLeads={leadsOriginalData}
                    duplicateLeads={leadsDuplicateData}
                    leadsList={leadsList}
                  />
                )}

                {!isLoading && leadsList.length === 0 && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <div>{infoMessageLoading}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row gy-12 gx-xl-12 mb-6'>
              <div className='card col-xl-12 mb-3 py-6'>

                {isLoading && (
                  <div className='py-12'>
                    <LoadingAnimation alignment='center' label='Generando tabla de leads...' size='lg' />
                  </div>
                )}

                {!isLoading && leadsList.length > 0 && (
                  <LeadsMainTable
                    calculateChartData={calculateChartData}
                    setLeadsDateData={setLeadsDateData}
                    setLeadsOriginalData={setLeadsOriginalData}
                    setLeadsDuplicateData={setLeadsDuplicateData}
                    leadsList={leadsList}
                    isLoading={isLoading}
                    infoMessageLoading={infoMessageLoading}
                    getLeadsByClient={getLeadsByClient}
                    leadsInTable={handleSetExport}
                  />
                )}

                {!isLoading && leadsList.length === 0 && (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <div>{infoMessageLoading}</div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de creacion manual de lead */}
      <Modal
        className='modal-default'
        show={showModalNewLead}
        onHide={closeModalLeadNew}
        scrollable
      >
        <ModalNewLead
          closeModalLeadNew={closeModalLeadNew}
          getLeadsByClient={getLeadsByClient}
        />
      </Modal>
    </>
  )
}

export { Leads }