// Importación hooks react
import {ChangeEvent, useState} from 'react'
/** Begin Import interactions report components */
import { InteractionCallReport } from './aux-components/interactionsTabs/InteractionCallReport'
import { InteractionMailReport } from './aux-components/interactionsTabs/InteractionMailReport'
import {InteractionWhatsappReport} from './aux-components/interactionsTabs/InteractionWhatsappReport'
/** End */

//End

//
export const InteractionsReport: React.FunctionComponent = () => {

  
  //Manejador del id del producto seleccionado
  const [selectedIdProduct, setSelectedIdProduct] = useState<string>('')

  //Manejador del nombre del producto seleccionado
  const [selectedNameProduct, setSelectedNameProduct] = useState<string>('')
  
  //Constante para el manejo de estado de la fecha de inicio para rango de seleccion de leads
  const [startDate, setStartDate] = useState('')

  //Constante para el manejo de estado de la fecha de final para rango de seleccion de leads
  const [endDate, setEndDate] = useState('')

  //Manejador del select de los años
  const [selectedYear, setSelectedYear] = useState<string>('2024')

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //Manejador para la data sin procesar obtenida en la peticion
  const [yearlyProductList, setYearlyProductList] = useState<any[]>([])

  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')


  //Funcion para realizar e cambio del producto
  const handleProductChange = async (productId: string, productName: string) => {
    setIsLoading(true)

    if (!productId && productId != '0') {
      //TODO: (BORRAR AL INTEGRAR LOS PRODUCTOS)
      setSelectedIdProduct('')
      setSelectedNameProduct('')
    } else if (productId === '0') {
      setSelectedIdProduct(productId)
      setSelectedNameProduct('Todos los productos')
      getDataProductList(productId, startDate, endDate)
      return
    } else {
      setSelectedIdProduct(productId)
      setSelectedNameProduct(productName)
      getDataProductList(productId, startDate, endDate)
      return
    }

    // const selectedProduct: Product | undefined = productsList.find((product: Product) => product.pro_id === productId)

    // if (selectedProduct) {
    //     setSelectedIdProduct(productId)
    //     setSelectedNameProduct(selectedProduct.name)
    //     getDataProductList(productId, selectedYear, duplicates)

    // } else {
    //     setSelectedIdProduct('')
    //     setSelectedNameProduct('')
    // }
  }
  //funcion para manejar el estado de la fecha de inicio
  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const start: Date = new Date(e.target.value)
    const end: Date = new Date(endDate)

    if (end <= start) {
      alert('La fecha de fin debe ser mayor que la fecha de inicio.')
      return
    }

    const differenceInDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)
    if (differenceInDays > 31) {
      alert('El rango de fechas no puede ser mayor a 31 días.')
      return
    }
    console.log('cambio de fecha inicial')
    setIsLoading(true)
    setStartDate(e.target.value)
  }

  //Funcion para manejar el estado de la fecha de fin
  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const start = new Date(startDate)
    const end = new Date(e.target.value)

    if (end <= start) {
      alert('La fecha de fin debe ser mayor que la fecha de inicio.')
      return
    }

    const differenceInDays = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)
    if (differenceInDays > 31) {
      alert('El rango de fechas no puede ser mayor a 31 días.')
      return
    }
    console.log('cambio de fecha final')
    setIsLoading(true)
    setEndDate(e.target.value)
  }

  //Funcion: //Todo: Cambiar por busqueda por fechas
  //Peticion principal para obtener la data del reporte
  const getDataProductList = (productId: string, startDate?: string, endDate?: string) => {
    setTimeout(() => {
      try {
        setYearlyProductList([1, 2])
        // const flattened = flattenData(newYearlyProductListt)
        // setProcessedDataForTable(flattened)
        // setChannelArray(flattened)
        setIsLoading(false)
        // handleChannelSelected('0');
      } catch (error) {
        console.log('Error obteniendo la data del reporte: ', error)
        setIsLoading(false)
        setInfoMessageLoading('Error en la petición')
      }
    }, 4000)
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center mb-2'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-column mb-6'>
              <h1 className='my-2'>
                Reporte de interacciones
                {/* <span className='fs-4 text-gray-500 fw-bold ps-2'>
                  1 Abril 2024 a 30 Abril 2024
                </span> */}
              </h1>
              {/* Begin Seccion para seleccionar fecha y producto */}
              {/* <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-150px me-3'>
                  <input
                    className='form-control form-control-solid'
                    type='date'
                    value='2024-04-01'
                  />
                </div>
                <div className='w-150px me-3'>
                  <input
                    className='form-control form-control-solid'
                    type='date'
                    value='2024-04-30'
                  />
                </div>

                <div className='w-300px me-3'>
                  <select
                    name='product'
                    data-control='selectProduct'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-lg'
                  >
                    <option value=''>Selecciona un producto</option>
                    <option value='0'>Todos los productos</option>
                  </select>
                </div>
              </div> */}
              {/* End */}
            </div>
          </div>
        </div>

        {/* Begin Seccion paracambiar tabs interacciones */}
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 d-flex justify-content-center'>
          <li className='nav-item'>
            <a className='nav-link fs-3 active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              Reporte por llamada
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link fs-3' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              Reporte por WhatsApp
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link fs-3' data-bs-toggle='tab' href='#kt_tab_pane_6'>
              Reporte por correo
            </a>
          </li>
        </ul>
        {/* End */}
        {/* Begin Filtro por producto y fecha */}
        {/* <div className='row justify-content-left mb-4'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='my-2 d-flex flex-wrap flex-stack'>
                {selectedNameProduct && startDate && endDate ? (
                  <div className='mb-2'>
                    Filtrado por:
                    <span className='ms-2 fs-3 text-gray-500 fw-bold'>
                      {selectedNameProduct} -
                      <span className='fs-4 text-gray-500 fw-bold ms-1'>
                        {startDate} a {endDate}
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className='mb-2'>Selecciona un producto</div>
                )}
              </h3>
              <div className='d-flex flex-wrap flex-stack align-items-center my-2'>
                <div className='w-280px me-4'>
                  <select
                    name='product'
                    data-control='selectProduct'
                    data-hidden-search='true'
                    className='form-select form-select-solid form-select-lg'
                    onChange={(e) => {
                      handleProductChange(
                        e.target.value,
                        e.target.options[e.target.selectedIndex].text
                      )
                    }}
                  >
                    <option value=''>Selecciona un producto</option>
                    <option value='0'>Todos los productos</option>
                    <option value='1'>Prueba producto 1</option>
                  </select>
                </div>
                <div className='d-flex align-items-center my-2'>
                  <div className='w-150px d-flex me-3'>
                    <input
                      name='startDate'
                      className='form-control form-control-solid'
                      type='date'
                      value={startDate}
                      onChange={(e) => {
                        handleStartDateChange(e)
                      }}
                    />
                  </div>

                  <div className='w-150px d-flex me-3'>
                    <input
                      name='endDate'
                      className='form-control form-control-solid'
                      type='date'
                      value={endDate}
                      max={startDate + 31}
                      onChange={(e) => {
                        handleEndDateChange(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End */}
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane py-5 fade show active' id='kt_tab_pane_4' role='tabpanel'>
            <InteractionCallReport />
          </div>
          <div className='tab-pane py-5 fade' id='kt_tab_pane_5' role='tabpanel'>
            <InteractionWhatsappReport />
          </div>
          <div className='tab-pane py-5 fade' id='kt_tab_pane_6' role='tabpanel'>
            <InteractionMailReport />
          </div>
        </div>

        {/* Begin Contenido de cuadros de alerta seleccionar producto (gráfica)
        <div className='row gx-2 mb-6'>
          <div className='col-xl-12'>
            <div className='card card-custom px-8 py-10 h-100'>
              <div className='row-justify-content-center mb-2'>
                <div className='col-md-12'>
                  {!selectedIdProduct && (
                    <div
                      className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                      role='alert'
                    >
                      <h5 className='mb-1 text-center'>Selecciona un producto</h5>
                    </div>
                  )}
                  {isLoading && selectedIdProduct && (
                    <div className='py-6'>
                      <LoadingAnimation alignment='center' label='Generando gráfica...' size='lg' />
                    </div>
                  )}
                  {/* Begin Seccion para componentes de reporte de interacciones 
                  {!isLoading && yearlyProductList.length > 0 && selectedIdProduct && (
                    <div>
                      <div
                        className='tab-pane py-5 fade show active'
                        id='kt_tab_pane_4'
                        role='tabpanel'
                      >
                        <InteractionCallReport />
                      </div>
                      <div className='tab-pane py-5 fade' id='kt_tab_pane_5' role='tabpanel'>
                        <InteractionWhatsappReport />
                      </div>
                      <div className='tab-pane py-5 fade' id='kt_tab_pane_6' role='tabpanel'>
                        <InteractionMailReport />
                      </div>
                      
                    </div>
                  )}
                  {/* End 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End */}
      </div>
    </>
    // <div className='container-fluid'>
    //   <div className='row justify-content-center'>
    //     <div className='col-md-6'>
    //       <div className='form-group'>
    //         <div className='input-group mb-3'>
    //           <span className='input-group-text' id='basic-addon3'>
    //             Fecha
    //           </span>
    //           <input
    //             type='text'
    //             className='form-control'
    //             id='basic-url'
    //             aria-describedby='basic-addon3'
    //             value={'13 de Mayo del 2023 a 30 de Junio del 2023'}
    //             readOnly
    //           ></input>
    //         </div>
    //       </div>
    //     </div>
    //     <div className='col-md-6'>
    //       <div className='form-group'>
    //         <div className='input-group mb-3'>
    //           <span className='input-group-text' id='basic-addon3'>
    //             Producto
    //           </span>
    //           <select className='form-select' aria-label='Default select example'>
    //             <option selected>DragonFest</option>
    //             <option value='1'>One</option>
    //             <option value='2'>Two</option>
    //             <option value='3'>Three</option>
    //             <option value='Mkt Dragoncem'>Mkt Dragoncem</option>
    //             <option value=''>IT</option>
    //             <option value=''>Evangelistas</option>
    //           </select>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='row' id='interCards'>
    //     <div className='col-xl-4'>
    //       <div className='card card-custom card-stretch gutter-b'>
    //         <img
    //           className='card-img-top'
    //           src='https://storage.googleapis.com/dragon-bucket-prod/app/assets/media/svg/illustrations/calls.png'
    //           alt='Card image cap'
    //           style={{height: '261px'}}
    //         ></img>
    //         <div className='card-header h-auto border-0'>
    //           <div className='card-title py-5'>
    //             <h3 className='card-label'>
    //               <span className='d-block text-dark font-weight-bolder'>Llamadas</span>
    //             </h3>
    //           </div>
    //           <div className='card-toolbar' id='CallsTableUser'>
    //             <span className='mr-5 d-flex align-items-center font-weight-bold'>
    //               <i className='label label-dot label-xl label-success mr-2'></i>Automática
    //             </span>
    //           </div>
    //         </div>
    //         <div className='card-body'>
    //           <div className='col-lg-12'>
    //             <div className='form-group'>
    //               <div className='input-group mb-3'>
    //                 <span className='input-group-text' id='basic-addon3'>
    //                   Creada por
    //                 </span>
    //                 <select className='form-select' aria-label='Default select example'>
    //                   <option selected>Automática</option>
    //                   <option value='1'>Prospecto</option>
    //                   <option value='2'>Asesor</option>
    //                 </select>
    //               </div>
    //             </div>
    //           </div>
    //           <button className='btn btn-primary'>Descargar</button>
    //           <div className='row'>
    //             <div className='py-5'>
    //               <table className='5 table table-row-dashed table-row-gray-300 gy-7'>
    //                 <thead>
    //                   <tr className='fw-bolder fs-6 text-gray-800'>
    //                     <th>Origen</th>
    //                     <th>Status</th>
    //                     <th>Llamadas</th>
    //                     <th>Acciones</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <td>DragonCem</td>
    //                     <td>Fuera de horario</td>
    //                     <td>Hubo llamada</td>
    //                     <td>
    //                       <button className='btn btn-outline-primary'>Ver</button>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>DragonCem</td>
    //                     <td>Fuera de horario</td>
    //                     <td>Hubo llamada</td>
    //                     <td>
    //                       <button className='btn btn-outline-primary'>Ver</button>
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className='col-xl-4'>
    //       <div className='card card-custom card-stretch gutter-b'>
    //         <img
    //           className='card-img-top'
    //           src='https://storage.googleapis.com/dragon-bucket-prod/app/assets/media/svg/illustrations/whatsapp.png'
    //           alt='Card image cap'
    //           style={{height: '261px'}}
    //         ></img>
    //         <div className='card-header h-auto border-0'>
    //           <div className='card-title py-5'>
    //             <h3 className='card-label'>
    //               <span className='d-block text-dark font-weight-bolder'>Whatsapp</span>
    //             </h3>
    //           </div>
    //           <div className='card-toolbar' id='CallsTableUser'>
    //             <span className='mr-5 d-flex align-items-center font-weight-bold'>
    //               <i className='label label-dot label-xl label-success mr-2'></i>Automática
    //             </span>
    //           </div>
    //         </div>
    //         <div className='card-body'>
    //           <div className='col-lg-12'>
    //             <div className='form-group'>
    //               <div className='input-group mb-3'>
    //                 <span className='input-group-text' id='basic-addon3'>
    //                   Creada por
    //                 </span>
    //                 <select className='form-select' aria-label='Default select example'>
    //                   <option selected>Automática</option>
    //                   <option value='1'>Prospecto</option>
    //                   <option value='2'>Asesor</option>
    //                 </select>
    //               </div>
    //             </div>
    //             <button className='btn btn-primary'>Descargar</button>
    //           </div>
    //           <div className='row'>
    //             <div className='py-5'>
    //               <table className='table table-row-dashed table-row-gray-300 gy-7'>
    //                 <thead>
    //                   <tr className='fw-bolder fs-6 text-gray-800'>
    //                     <th>Origen</th>
    //                     <th>Status</th>
    //                     <th>Llamadas</th>
    //                     <th>Acciones</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <td>DragonCem</td>
    //                     <td>Fuera de horario</td>
    //                     <td>Hubo llamada</td>
    //                     <td>
    //                       <button className='btn btn-outline-primary'>Ver</button>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>DragonCem</td>
    //                     <td>Fuera de horario</td>
    //                     <td>Hubo llamada</td>
    //                     <td>
    //                       <button className='btn btn-outline-primary'>Ver</button>
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className='col-xl-4'>
    //       <div className='card card-custom card-stretch gutter-b'>
    //         <img
    //           className='card-img-top'
    //           src='https://storage.googleapis.com/dragon-bucket-prod/app/assets/media/svg/illustrations/mails.png'
    //           alt='Card image cap'
    //           style={{height: '261px'}}
    //         />
    //         <div className='card-header h-auto border-0'>
    //           <div className='card-title py-5'>
    //             <h3 className='card-label'>
    //               <span className='d-block text-dark font-weight-bolder'>Correos</span>
    //             </h3>
    //           </div>
    //           <div className='card-toolbar' id='CallsTableUser'>
    //             <span className='mr-5 d-flex align-items-center font-weight-bold'>
    //               <i className='label label-dot label-xl label-success mr-2'></i>Automática
    //             </span>
    //           </div>
    //         </div>
    //         <div className='card-body'>
    //           <div className='col-lg-12'>
    //             <button className='btn btn-primary'>Descargar</button>
    //           </div>
    //           <div className='row'>
    //             <div className='py-5'>
    //               <table className='table table-row-dashed table-row-gray-300 gy-7'>
    //                 <thead>
    //                   <tr className='fw-bolder fs-6 text-gray-800'>
    //                     <th>Origen</th>
    //                     <th>Status</th>
    //                     <th>Llamadas</th>
    //                     <th>Acciones</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <td>DragonCem</td>
    //                     <td>Fuera de horario</td>
    //                     <td>Hubo llamada</td>
    //                     <td>
    //                       <button className='btn btn-outline-primary'>Ver</button>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td>DragonCem</td>
    //                     <td>Fuera de horario</td>
    //                     <td>Hubo llamada</td>
    //                     <td>
    //                       <button className='btn btn-outline-primary'>Ver</button>
    //                     </td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}
