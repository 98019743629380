// Importamos hooks de react que usaremos para este componente
import { FC } from 'react'
// Importamos helpers de metronic
import { KTIcon } from '../../../../_metronic/helpers'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de account-management que usaremos para este componente
import { ProductByClientBU } from '../interfaces/accountManagerTypes'

// *************************************************************************************************************

// Tipado de Props para el componente SelectedClientItems
type PropsCSIC = {
  productsByClientList: ProductByClientBU[];
  inactiveClientsCount: number;
  activeClientsCount: number;
  pendingClientsCount: number;
  pendingClientsCountToActive: number;
}

// Construimos un funtional component para reenderizar ClientSelectedProductsCard
const SelectedClientItems: FC<PropsCSIC> = ({
  productsByClientList,
  inactiveClientsCount,
  activeClientsCount,
  pendingClientsCount,
  pendingClientsCountToActive
}) => {

  return (
    <>
      <div className='d-flex flex-wrap justify-content-center'>

        {/* Begin: Total de clientes activos */}
        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-on' className='fs-3 text-success me-2' />
            <div className='fs-2 fw-bolder'>{activeClientsCount}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>Total de clientes activos</div>
        </div>
        {/* End: Total de clientes activos */}

        {/* Begin: Total de clientes con proceso pendiente */}
        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='time' className='fs-3 text-warning me-2' />
            <div className='fs-2 fw-bolder'>{pendingClientsCount}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>Total de clientes por desactivar</div>
        </div>
        {/* End: Total de clientes con proceso pendiente */}

        {/* Begin: Total de clientes con proceso pendiente */}
        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-8 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='time' className='fs-3 text-warning me-2' />
            <div className='fs-2 fw-bolder'>{pendingClientsCountToActive}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>Total de clientes por activar</div>
        </div>
        {/* End: Total de clientes con proceso pendiente */}

        {/* Begin: Total de clientes inactivos */}
        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
          <div className='d-flex align-items-center'>
            <KTIcon iconName='toggle-off' className='fs-3 text-danger me-2' />
            <div className='fs-2 fw-bolder'>{inactiveClientsCount}</div>
          </div>
          <div className='fw-bold fs-6 text-gray-400'>Total de clientes inactivos</div>
        </div>
        {/* End: Total de clientes inactivos */}

      </div>
    </>
  )
}

// Exportamos componentes SelectedClientItems y ClientSelectedProductsCard
export { SelectedClientItems }