// Importamos hooks de react que usaremos para este componente
import { FC, useContext, useState, useEffect } from 'react'
// Importamos componente Modal de la libreria de bootstrap para react
import { Modal } from 'react-bootstrap'
// Importamos libreria para manejo de lenguaje
import { useIntl } from 'react-intl'
// Importamos constante con prefijo para el titulo de la pagina
import { DragonCem } from '../../utils/constants/_Constants'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from '../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos el componente CardProducts
import { CardProducts } from './aux-components/CardProducts'
// Importamos el componente ModalNewProduct
import { ModalNewProduct } from './modals/ModalNewProduct'
// Importamos componente con la animacion de carga de registros
import { LoadingAnimation } from '../../utils/components/loading/LoadingAnimation'

// ---------------------------------------------------------------------------------------

// Importamos la funcion productsListByBrand y productsListByClient para conexion con API de productos
import { productsListByBrand, productsListByClient, productsListAssignedByUser } from './services/_ProductsListApi'
// Importamos la funcion brandInfoById para conexion con API de marcas
import { brandInfoById } from '../brands/services/_BrandsListApi'

// ---------------------------------------------------------------------------------------

// Importamos funciones sessionExpired, dateFormatter y countArrayObjects
// para vencimiento de sesion, formateo de fecha y conteo de objetos de arrays
import {
  sessionExpired,
  dateFormatterTextualEn,
  countArrayObjects,
  setPageTitle,
} from '../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de productos que usaremos para este componente
import { Address, DataProduct, DataTwl, Product } from './interfaces/productsTypes'
import { KTIcon } from '../../../_metronic/helpers'
import { DropdownExportProducts } from '../../../_metronic/partials/content/dropdown/DropdwonExportProduts'

// Iniciamos funcion para construir componente Products
const Products: FC = () => {
  //console.log('inicia renderizacion del componente products');

  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext)
  const clientId = user.cli_id
  const lvlUser = user.user_type
  const userToken = token
  // console.log(lvlUser);

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')

  //Variables para manejo de estado del filtro de estatus
  const [selectedFilter, setSelectedFilter] = useState(1)

  //Variable para el manejo de estado del nombre y id de marca del cual viene
  const [brandName, setBrandName] = useState('')

  //Variables para manejo de estado de la paginacion
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage = 6

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Variables para el manejo de estado del modal de creacion de marca
  const [showModal, setShowModal] = useState<boolean>(false)

  //Varibles para el manejo de estado del arreglo con los productos del cliente
  const [productsList, setProductsList] = useState<Product[]>([])

  //Variables para el manejo del estado de la información de los products que se exportaran en csv y xlsx
  const [exportData, setExportData] = useState({})

  const resetForm = () => {
    // setFormData();
  }

  //Funcion para abrir modal
  const openModal = () => {
    setShowModal(true)
  }

  //Funcion para cerrar modal
  const closeModal = () => {
    setShowModal(false)
  }

  //Función para definir el estado de la data a exportar al momento de dar click
  const handleSetExport = () => {
    setExportData(filterDataToExport)
    console.log('Se definió')
    console.log(filterDataToExport)
  }

  // console.log('lista de productos: ', productsList)

  //Funcion para determinar el tamaño del arreglo de listas de marcas
  const checkProductsListLength = () => {
    //Si el arreglo tiene un tamaño igual a cero mandamos mensaje de productos no existentes
    productsList.length === 0 && setInfoMessageLoading('No has creado ningun producto')
  }

  //Funcion para determinar si esta llegando a productos desde una marca o directamente
  const propertyChildBrand = async (): Promise<void> => {
    const currentUrl = window.location.href

    const hasBrandParam = currentUrl.includes('brand=')

    const brandValue = new URLSearchParams(window.location.search).get('brand')

    if (hasBrandParam && brandValue) {
      try {
        const { data: jsonData, code, msg } = await brandInfoById(userToken, brandValue)

        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
          return
        } else {
          const brandNameReceived = jsonData.data.name

          console.log('nombre de la marca: ', brandNameReceived)
          // return brandNameReceived || 'General'; // Devuelve el nombre de la marca o 'General' si no está definido
          setBrandName(brandNameReceived)
        }
      } catch (error) {
        // Manejar el error de manera adecuada (log, notificar, etc.)
        console.error('Error al obtener la información de la marca:', error)
        // throw error;
      }
    } else {
      setBrandName('generic')
      // return 'generic'; // Si no hay parámetro de marca, devuelve 'General'
    }
  }

  //Funcion para conectarnos al servidor y obtener el listado de marcas del cliente
  const getProductsRegisters = async (): Promise<void> => {
    // const { brand } = useParams<{ brand?: string }>();
    // Obtener la URL actual
    const currentUrl = window.location.href

    // Verificar si la URL contiene el parámetro 'brand'
    const hasBrandParam = currentUrl.includes('brand=')

    // Obtener el valor del parámetro 'brand'
    const brandValue = new URLSearchParams(window.location.search).get('brand')

    if (hasBrandParam && brandValue) {
      try {
        const { data: jsonData, code, msg } = await productsListByBrand(userToken, brandValue)
        // console.log('data prod: ', dataProducts.data)

        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
          setIsLoading(false)
          return
        } else {
          propertyChildBrand()

          const productsListReceived = jsonData.data

          const userTokenNew = await jsonData.token

          updateToken(userTokenNew)
          // console.log({productsAddressReceived});

          // console.log('actualiza token productsList (products): ', userTokenNew)

          const productsCards = productsListReceived.map((product: Product) => {
            const productDateAddFormatted = dateFormatterTextualEn(product.date_add)

            const totalCampaigns = countArrayObjects(product.campaigns)

            const addressProduct: Address = {
              country: product.address.country,
              state: product.address.state,
              city: product.address.city,
              zone: product.address.zone,
            }

            const dataProductInfo: DataProduct = {
              merch_pdf: product.data_product.merch_pdf,
              price: product.data_product.price,
              mails: product.data_product.mails,
              duplicates: product.data_product.duplicates,
              parser: product.data_product.parser,
              merch: product.data_product.merch,
              logo: product.data_product.logo,
              header: product.data_product.header,
              whaimg: product.data_product.whaimg,
              intro: product.data_product.intro,
              virtual: product.data_product.virtual,
              web: product.data_product.web,
              gmaps: product.data_product.gmaps,
              waze: product.data_product.waze,
              legals: product.data_product.legals,
            }

            const dataTwlProduct: DataTwl = {
              twl_wab: product.data_twl.twl_wab,
              twl_lead_service: product.data_twl.twl_lead_service,
              twl_sellwab: product.data_twl.twl_sellwab,
              twl_sell_service: product.data_twl.twl_sell_service,
              twl_sms: product.data_twl.twl_sms,
              twl_sms_accsid: product.data_twl.twl_sms_accsid,
              twl_sms_accat: product.data_twl.twl_sms_accat,
              twl_callid: product.data_twl.twl_callid,
              twl_call_accsid: product.data_twl.twl_call_accsid,
              twl_call_accat: product.data_twl.twl_call_accat,
              wab_status: product.data_twl.wab_status
            }

            // const iscoreProduct: Iscore = {
            //   data_update: product.iscore.data_update,
            // };

            return {
              address: addressProduct,
              data_product: dataProductInfo,
              data_twl: dataTwlProduct,
              // iscore: iscoreProduct,
              pro_id: product.pro_id,
              // brand_id: product.bid,
              crm_id: product.crm_id,
              status: product.status,
              name: product.name,
              type: product.type,
              date_add: productDateAddFormatted,
              date_down: product.date_down,
              sellwab_status: product.sellwab_status,
              sms_status: product.sms_status,
              wab_mdl_status: product.wab_mdl_status,
              wab_status: product.wab_status,
              campaignsActive: totalCampaigns,
              campaigns: product.campaigns,
            }
          })

          setProductsList(productsCards)
          //Ocultamos la animacion de carga
          setIsLoading(false)
          // console.log(productsList);
          /* Ejecutamos la funcion para verificar  
          el tamaño del arreglo de marcas */
          checkProductsListLength()
        }
      } catch (error) {
        setInfoMessageLoading('Ocurrio un error al intentar obtener los productos')
        setIsLoading(false)
        // console.log('Error receiving products: ', error);
      }
    } else {
      try {
        const { data: jsonData, code, msg } = await productsListAssignedByUser(userToken)

        if (code === 401 && msg === 'Token no valido') {
          sessionExpired(intl.formatMessage({ id: 'GLOBAL.ALERT.SESSION_EXPIRED' }))
          setIsLoading(false)
          return
        } else {
          propertyChildBrand()

          const productsListReceived = jsonData.data

          const userTokenNew = await jsonData.token

          updateToken(userTokenNew)
          // console.log({productsAddressReceived});

          // console.log('actualiza token productsList (products): ', userTokenNew)

          const productsCards = productsListReceived.map((product: Product) => {
            const productDateAddFormatted = dateFormatterTextualEn(product.date_add)

            const totalCampaigns = countArrayObjects(product.campaigns)

            const addressProduct: Address = {
              country: product.address.country,
              state: product.address.state,
              city: product.address.city,
              zone: product.address.zone,
            }

            const dataProductInfo: DataProduct = {
              merch_pdf: product.data_product.merch_pdf,
              price: product.data_product.price,
              mails: product.data_product.mails,
              duplicates: product.data_product.duplicates,
              parser: product.data_product.parser,
              merch: product.data_product.merch,
              logo: product.data_product.logo,
              header: product.data_product.header,
              whaimg: product.data_product.whaimg,
              intro: product.data_product.intro,
              virtual: product.data_product.virtual,
              web: product.data_product.web,
              gmaps: product.data_product.gmaps,
              waze: product.data_product.waze,
              legals: product.data_product.legals,
            }

            const dataTwlProduct: DataTwl = {
              twl_wab: product.data_twl.twl_wab,
              twl_lead_service: product.data_twl.twl_lead_service,
              twl_sellwab: product.data_twl.twl_sellwab,
              twl_sell_service: product.data_twl.twl_sell_service,
              twl_sms: product.data_twl.twl_sms,
              twl_sms_accsid: product.data_twl.twl_sms_accsid,
              twl_sms_accat: product.data_twl.twl_sms_accat,
              twl_callid: product.data_twl.twl_callid,
              twl_call_accsid: product.data_twl.twl_call_accsid,
              twl_call_accat: product.data_twl.twl_call_accat,
              wab_status: product.data_twl.wab_status
            }

            // const iscoreProduct: Iscore = {
            //   data_update: product.iscore.data_update,
            // };

            return {
              address: addressProduct,
              data_product: dataProductInfo,
              data_twl: dataTwlProduct,
              // iscore: iscoreProduct,
              pro_id: product.pro_id,
              // brand_id: product.bid,
              crm_id: product.crm_id,
              status: product.status,
              name: product.name,
              type: product.type,
              date_add: productDateAddFormatted,
              date_down: product.date_down,
              sellwab_status: product.sellwab_status,
              sms_status: product.sms_status,
              wab_mdl_status: product.wab_mdl_status,
              wab_status: product.wab_status,
              campaignsActive: totalCampaigns,
              campaigns: product.campaigns,
            }
          })

          setProductsList(productsCards)
          //Ocultamos la animacion de carga
          setIsLoading(false)
          // console.log(productsList);
          /* Ejecutamos la funcion para verificar  
          el tamaño del arreglo de marcas */
          checkProductsListLength()
        }
      } catch (error) {
        setInfoMessageLoading('Ocurrio un error al intentar obtener las marcas de este cliente')
        setIsLoading(false)
        // console.log('Error receiving products: ', error);
      }
    }
  }

  useEffect(() => {
    // Cambiar el título de la página al montar el componente
    setPageTitle(`${intl.formatMessage({ id: 'PRODUCTS.TITLE' })} | ${DragonCem}`)
    getProductsRegisters()
  }, [selectedFilter])

  // const reRenderProducts = () => {
  //   getProductsRegisters();
  // }

  //Definimos una variable independiente de una función para ser la que
  //cambie constantemente y guarde la data de lo que se exportará
  let filterDataToExport = {}

  const getTotalEntries = () => {
    const filteredEntries = productsList.filter((product) => {
      if (selectedFilter === 0) return true
      if (selectedFilter === 1) return product.status === 1
      if (selectedFilter === 2) return product.status === 0
      if (selectedFilter === 3) return product.status === 2

      return false
    })

    console.log('Contenido filtrado:', filteredEntries)
    filterDataToExport = filteredEntries

    return filteredEntries.length
  }

  const renderProductCards = (getProductsRegisters: () => void) => {
    return productsList
      .filter((product) => {
        if (selectedFilter === 0) return 1
        if (selectedFilter === 1) return product.status === 1
        if (selectedFilter === 2) return product.status === 0
        if (selectedFilter === 3) return product.status === 2
      })
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
      .map((product, index) => (
        <div key={index} className='col-md-6 col-xl-4'>
          <CardProducts
            infoProduct={[product.data_product]}
            twlProduct={[product.data_twl]}
            dateDownProduct={product.date_down}
            sellWabStatusProduct={product.sellwab_status}
            smsStatusProduct={product.sms_status}
            wabMdlStatusProduct={product.wab_mdl_status}
            wabStatusProduct={product.wab_status}
            campaignsProduct={product.campaigns}
            idProduct={product.pro_id}
            addressProduct={[product.address]}
            crmIdProduct={product.crm_id}
            statusProduct={product.status}
            nameProduct={product.name}
            typeProduct={product.type}
            dateAddProduct={product.date_add}
            parserProduct={product.data_product.parser}
            mailsProduct={product.data_product.mails}
            legalsProduct={product.data_product.legals}
            priceProduct={product.data_product.price}
            countryProduct={product.address.country}
            stateProduct={product.address.state}
            cityProduct={product.address.city}
            zoneProduct={product.address.zone}
            gmapsProduct={product.data_product.gmaps}
            wazeProduct={product.data_product.waze}
            webProduct={product.data_product.web}
            virtualProduct={product.data_product.virtual}
            introProduct={product.data_product.intro}
            merchPdfProduct={product.data_product.merch_pdf}
            logoProduct={product.data_product.logo}
            headerProduct={product.data_product.header}
            whaImgProduct={product.data_product.whaimg}
            // reRenderProducts={reRenderProducts}
            campaignsActive={product.campaignsActive}
            getProductsRegisters={getProductsRegisters}
          />
        </div>
      ))
  }

  const renderPagination = () => {
    return (
      <ul className='pagination'>
        {Array.from({ length: Math.ceil(getTotalEntries() / itemsPerPage) }).map((_, index) => {
          const page = index + 1
          const totalPages = Math.ceil(getTotalEntries() / itemsPerPage)

          if (
            (page <= 3 && currentPage <= 5) ||
            (page > currentPage - 2 && page < currentPage + 2) ||
            (page > totalPages - 2 && currentPage <= totalPages - 4)
          ) {
            return (
              <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                <button className='page-link' onClick={() => setCurrentPage(page)}>
                  {page}
                </button>
              </li>
            )
          }

          if (
            (page === 4 && currentPage > 5) ||
            (page === totalPages - 1 && currentPage < totalPages - 4)
          ) {
            return (
              <li key={index} className='page-item disabled'>
                <span className='page-link'>...</span>
              </li>
            )
          }

          return null
        })}
      </ul>
    )
  }

  const renderFilterDropdown = () => {
    return (
      <div className='w-200px me-5'>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-white form-select-sm'
          defaultValue='1'
          onChange={(e) => {
            setSelectedFilter(Number(e.target.value))
            setCurrentPage(1)
          }}
        >
          <option value='0'>{intl.formatMessage({ id: 'BRANDS.TITLE.FILTER_ALL' })}</option>
          <option value='1'>{intl.formatMessage({ id: 'BRANDS.TITLE.FILTER_ACTIVE' })}</option>
          <option value='2'>{intl.formatMessage({ id: 'BRANDS.TITLE.FILTER_UNACTIVE' })}</option>
          <option value='3'>Con proceso pendiente</option>
        </select>
      </div>
    )
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row jostify-content-center'>
          <div className='col-md-12'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h1 className='my-2'>
                {intl.formatMessage({ id: 'PRODUCTS.TITLE' })}
                {/* {propertyChildBrand()} */}
                <span className='fs-4 text-gray-500 fw-bold ms-1'>
                  {brandName !== 'generic' ? (
                    <> From Brand: {brandName} </>
                  ) : (
                    <> From All Client </>
                  )}
                </span>
              </h1>

              <div className='d-flex flex-wrap my-2'>
                {renderFilterDropdown()}

                {lvlUser === 'DEVELOPER' || lvlUser === 'SUPER_ADMIN' ? (
                  <button type='button' className='btn btn-primary btn-sm me-3' onClick={openModal}>
                    {intl.formatMessage({ id: 'PRODUCTS.BUTTON.NEW_PRODUCT' })}
                  </button>
                ) : null}

                <button
                  type='button'
                  className='btn btn-primary d-flex align-items-center btn-sm me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={handleSetExport}
                >
                  <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                  <span>Exportar datos</span>
                </button>
                <DropdownExportProducts dataToExport={exportData} title={'Products'} />

                <Modal show={showModal} onHide={closeModal} scrollable className='modal-lg'>
                  <Modal.Header closeButton onClick={resetForm}>
                    <Modal.Title>
                      <h1>{intl.formatMessage({ id: 'PRODUCTS.BUTTON.NEW_PRODUCT' })}</h1>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ModalNewProduct
                      // reRenderProducts={reRenderProducts}
                      closeModal={closeModal}
                      getProductsRegisters={getProductsRegisters}
                      setCurrentPage={setCurrentPage}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className='btn btn-sm btn-secondary'
                      type='button'
                      onClick={() => {
                        closeModal()
                        // resetform();
                      }}
                    >
                      {intl.formatMessage({ id: 'PRODUCTS.MODAL_NEW.RESET_BUTTON' })}
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>

            <div className='row g-6 g-xl-9' id='card-product-container'>
              {/* Validaciones para determinar que elementos mostraremos en la pantalla */}

              {/* Si isLoading es igual a 'true' lo mostramos */}
              {isLoading && <LoadingAnimation alignment='center' label='Obteniendo productos...' size='lg' />}

              {/* Si isLoading es diferente de 'true' y el tamaño de productsList
              es mayor a 0 mostramos el componente de tarjetas de marcas */}
              {!isLoading && productsList.length > 0 && renderProductCards(getProductsRegisters)}

              {/* Si isLoading es diferente de 'true' y el tamaño de productsList
              es igual a 0 mostramos el mensaje informativo */}
              {!isLoading && productsList.length === 0 && <p>{infoMessageLoading}</p>}
            </div>
            <div className='d-flex flex-stack flex-wrap pt-10'>
              <div className='fs-6 fw-bold text-gray-700'>
                {`${intl.formatMessage({ id: 'PRODUCTS.PAGINATION_SHOWING' })} ${(currentPage - 1) * itemsPerPage + 1} ${intl.formatMessage({ id: 'PRODUCTS.PAGINATION_TO' })}  ${Math.min(
                  currentPage * itemsPerPage,
                  getTotalEntries()
                )} ${intl.formatMessage({ id: 'PRODUCTS.PAGINATION_OF' })} ${getTotalEntries()} ${intl.formatMessage({ id: 'PRODUCTS.PAGINATION_RESULTS' })}`}
              </div>
              {renderPagination()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Products }
