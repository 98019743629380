/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import {LoginApi} from '../services/_LoginApi'
import { UserContext

 } from '../../app-context/user-context/UserContext'
// import {getkcUserByToken, login} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import {useAuth} from '../core/Auth'

const initialValues = {
  mail: '',
  password: '',
}

/* 
  Formik+YUP+Typescript: 
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops 
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e 
*/

export function Login() {
  const [mail, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleLogin = async () => {
    setError(null) // Borra el mensaje de error antes de hacer la solicitud
    try {
      const jsonData = await LoginApi(mail, password)
      handleRememberUser()
      // Hacer algo con los datos si es necesario
      if (jsonData.status === 200) console.log(jsonData)
    } catch (error) {
      console.error('Error en la solicitud Axios:', error)
      setError('Hubo un error en el inicio de sesión. Verifica tus credenciales.')
    }
  }
  const handleRememberUser = () => {
    
    if (localStorage.getItem('user')) {
      if(isChecked){
        //Existe el local storage, validar si recordar o no
        localStorage.setItem('user', mail)
      }else{
        localStorage.removeItem('user')
      }
    } else {
      if(isChecked){
        //Existe el local storage, validar si recordar o no
        localStorage.setItem('user', mail)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(()=> {
    const user = localStorage.getItem('user');
    if(user){
      setEmail(user)
      setIsChecked(true)
    }else{
      setIsChecked(false)
    }
  },[])

  return (
    <form className='form w-100' noValidate id='kt_login_signin_form'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <img
          className='mb-4'
          src='https://storage.googleapis.com/dragon-bucket-prod/app/img/LogoLogin.png'
          alt='icon'
        />
        <h1 className='text-dark fw-bolder mb-2'>Inicia sesión</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Con tu correo electrónico y contraseña</div>
      </div>
      {/* begin::Heading */}

      {error && <div className='text-danger'>{error}</div>}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Correo electrónico</label>
        <input
          placeholder='user@domain.com'
          className='form-control bg-transparent'
          type='email'
          name='mail'
          autoComplete='email'
          value={mail}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
        <input
          placeholder='*****'
          type='password'
          autoComplete='current-password'
          className='form-control bg-transparent'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        {/* begin::Link */}
        <div className='form-check'>
          <input className='form-check-input' type='checkbox' value='' id='defaultCheck1' onChange={handleChange} checked={isChecked}/>
          <label className='form-check-label'>Recordar mi usuario</label>
        </div>
        {/* end::Link */}
        {/* begin::Link */}
        <Link to='/auth/recover-password' className='link-primary'>
          Recuperar contraseña
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          onClick={(e) => {
            e.preventDefault()
            handleLogin()
          }}
        >
          <span className='indicator-label'>Continue</span>
        </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'> 
        Not a Member yet?{' '} 
        <Link to='/auth/registration' className='link-primary'> 
          Sign up 
        </Link> 
      </div>  */}
    </form>
  )
}
