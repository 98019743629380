import React, { FC, useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../../../../app-context/user-context/UserContext'
import { LoadingAnimation } from '../../../../../../utils/components/loading/LoadingAnimation'
import { CallLeadDetail, Call, Lead, LeadDetailInfo } from '../../../../interfaces/leadsTypes'
import { callsRegistersLeadDetail } from '../../../../services/_CallsRegistersApi'
import { sessionExpired, dateFormatterTextualEnExtended } from '../../../../../../utils/functions/_Functions'

type Props = {
  leadData: LeadDetailInfo | undefined;
  currentNavSelected: string;
}

export const CallsNav: FC<Props> = ({
  leadData,
  currentNavSelected
}) => {

  console.log('se esta renderizando el componente llamadas')
  console.log(currentNavSelected)

  //Estado inicial del item de llamadas para evitar datos undefined
  const initialCallsItem: CallLeadDetail = {
    calls: [],
    callsCount: 0,
  }

  //Algunas variables desde el usercontext
  const { user, token, updateToken } = useContext(UserContext);
  const clientId = user.cli_id;
  const userToken = token;

  //Constante para manejar el item con el listado de llamadas
  const [callsItem, setCallsItem] = useState<CallLeadDetail>(initialCallsItem);

  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>("");

  //Funcion para verificar el tamaño del arreglo de llamadas
  const checkCallsListLength = () => {
    //Seteamos mensaje informativo
    callsItem.calls.length === 0 && setInfoMessageLoading("No se encontro ningún registro de llamadas")
  }


  //Funcion para obtener el registro de llamadas por id de lead
  const getCallsByLead = async (): Promise<void> => {
    try {
      const { data: jsonData, code, msg } = await callsRegistersLeadDetail(userToken, leadData?.leadId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired('La sesion ha expirado')
        return;
      } else {
        const callsRegistersReceived = jsonData.initialData
        const totalCalls = jsonData.callsCount

        const userTokenNew = jsonData.token

        updateToken(userTokenNew)

        const CallRecordingRow = callsRegistersReceived.map((call: Call) => {

          const callDateAddFormatted = dateFormatterTextualEnExtended(call.callDateAdd)

          return {

            callId: call.callId,
            callDateAdd: callDateAddFormatted,
            callChannel: call.callChannel,
            callSource: call.callSource,
            callDuration: call.callDuration,
            leadId: call.leadId,
            leadName: call.leadName,
            leadStatus: call.leadStatus,
            callPhone: call.callPhone,
            callSellerId: call.callSellerId,
            callSellerName: call.callSellerName,
            callSellerStatus: call.callSellerStatus,
            callRecording: call.callRecording,
            callOrigin: ''
          }
        })

        setCallsItem({
          calls: CallRecordingRow,
          callsCount: totalCalls,
        })

        //Ocultamos la animacion de carga
        setIsLoading(false);
        //Verificamos el tamaño del arreglo de llamadas disparando la funcion
        checkCallsListLength()

      }

    } catch (error) {
      console.log('Error obteniedo las llamadas: ', error)
      //Ocultamos la animacion de carga
      setIsLoading(false);
      //Seteamos mensaje informativo
      setInfoMessageLoading('Hubo un error en la petición')
    }

  }

  /*Disparamos la funcion de obtencion de registros al 
  montar el componente*/
  useEffect(() => {
    getCallsByLead();
  }, [])

  return (
    <>
      <div className='container ml-15 mt-4 py-12' id='llamadas-container'>

        {/* Si isLoading es igual a 'true' lo mostramos */}
        {isLoading && <div className='py-12'><LoadingAnimation alignment='center' label='Obteniendo registros de llamadas...' size='lg' /></div>}

        {/* Si isLoading es diferente a 'true' y el arreglo de llamadas es mayor a '0' montamos información */}
        {!isLoading && callsItem.calls.length > 0 && (
          <>
            <div className="mb-8">
              <h4>Total de llamadas: {callsItem.callsCount}</h4>
            </div>

            {callsItem.calls.map((call: Call, index: number) => (
              <div key={index} className='timeline timeline-1 my-8'>
                <div className='timeline-sep bg-primary-opacity-20'></div>
                <div className='timeline-item'>
                  <div className='text-muted'>{call.callDateAdd}</div>
                  <div className='timeline-content font-weight-normal ml-6'>
                    <p>
                      <strong>ID de llamada: </strong>{call.callId}
                    </p>
                    <p>
                      <strong>Origen de la llamada: </strong>
                      {call.callSource === 1 ? (
                        <span>Medio Online</span>
                      ) : call.callSource === 2 ? (
                        <span>Medio Offline</span>
                      ) : call.callSource === 3 ? (
                        <span>Whatsapp Usuario</span>
                      ) : call.callSource === 4 ? (
                        <span>Whatsapp Vendedor</span>
                      ) : <p></p>}
                    </p>
                    <p>
                      <strong>Duración: </strong>{call.callDuration} min
                    </p>
                    <p>
                      <strong>Estatus: </strong>
                      {call.leadStatus === 'completed' ? (
                        <span className='badge badge-light-success fw-bolder fs-7 px-2 py-1 ml-4'>{call.leadStatus}</span>
                      ) : call.leadStatus === 'Ocupado' || call.leadStatus === 'Colgó' ? (
                        <span className='badge badge-light-warning fw-bolder fs-7 px-2 py-1 ml-4'>{call.leadStatus}</span>
                      ) : call.leadStatus === 'No disponible' || call.leadStatus === 'cancelled' || call.leadStatus === 'Sin enlace' ? (
                        <span className='badge badge-light-danger fw-bolder fs-7 px-2 py-1 ml-4'>{call.leadStatus}</span>
                      ) : (
                        <span className='badge badge-light-info fw-bolder fs-7 px-2 py-1 ml-4'>{call.leadStatus}</span>
                      )}
                    </p>
                    <p>
                      <strong>Vendedor: </strong>
                      {call.callSellerStatus === 'completed' ? (
                        <span className='badge badge-light-success fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      ) : call.callSellerStatus === 'Ocupado' || call.callSellerStatus === 'Colgo' ? (
                        <span className='badge badge-light-warning fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      ) : call.callSellerStatus === 'No disponible' || call.callSellerStatus === 'cancelled' ? (
                        <span className='badge badge-light-danger fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      ) : (
                        <span className='badge badge-light-info fw-bolder fs-7 px-2 py-1 ml-4'>{call.callSellerStatus}</span>
                      )}
                    </p>
                    <p>
                      {/* {call.callRecording !== 'SIN GRABACION' ? (
                        <a href={call.callRecording} className='btn-sm btn btn-primary font-weight-bold mr-2'>Escuchar grabación</a>
                      ) : (
                        <a href='' className='btn-sm btn btn-danger font-weight-bold mr-2'>Sin grabación</a>
                      )} */}
                      <button
                        className={`btn-sm btn btn-${call.callRecording === 'SIN GRABACION' ? 'danger' : 'primary'} font-weight-bold mr-2`}
                        onClick={() => call.callRecording !== 'SIN GRABACION' && window.open(call.callRecording, '_blank')}
                        disabled={call.callRecording === 'SIN GRABACION'}
                      >
                        {call.callRecording !== 'SIN GRABACION' ? 'Escuchar grabación' : 'Sin grabación'}
                      </button>

                    </p>
                    <div className='separator separator-solid separator-border-1'></div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {/* //Si isLoading es diferente a 'true' y el arreglo de llamadas es iguala '0' mostramos mensaje de error */}
        {!isLoading && callsItem.calls.length === 0 && (
          <div className='py-12'><p>{infoMessageLoading}</p></div>
        )}

      </div>

    </>
  )
}
