// Importamos hooks de react que usaremos para este componente
import React, { useState, useContext, useEffect } from "react";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import { UserContext } from "../../../../app-context/user-context/UserContext";

// ---------------------------------------------------------------------------------------

// Importamos la funcion campaignConnectInfoEdit para conexion con API de campañas
import { campaignConnectInfoEdit } from "../../services/_CampaignsEditApi";

// ---------------------------------------------------------------------------------------

/* Importamos funcion sessionExpired, convertObjectsToText y countKeys para vencimiento de sesion
para conversion de objetos a texto y conteo de indices */
import { convertObjectsToText, countKeys, sessionExpired } from "../../../../utils/functions/_Functions";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import { AlertInfo, CrmSetup, MailTemplate, WabaBot, WabaTemplate, GradeSetupList, GradeSetup } from '../../../../utils/interfaces/_InterfacesTypes';

import { gradesSetupListByProduct } from "../../../../utils/services/_GradesSetupListApi";

import { mailTemplatesListByProduct } from "../../../../utils/services/_MailTemplatesApi";

// Iniciamos funcion para construir componente ConnectTabForm
function ConnectTabForm({productId, formDataEditConnect, setFormDataEditConnect, cam_idCampaign, closeModalEdit, getCampaignsRegisters}){
    
    console.log("grade_options: ", formDataEditConnect.grade_options);

    const initialCrmSetupList: CrmSetup = {
        client_id: '',
        crm_setups: [
            {
                crm_id: '',
                crm_name: '',
                crm_description: '',
            }
        ]
    }

    const initialMailTemplatesList: string[] = ['']

    const initialWabaBotsList: WabaBot = {
        client_id: '',
        wab_bots: [
            {
                wab_bot_id: '',
                wab_bot_name: '',
                wab_bot_description: '',
            }
        ]
    }

    const initialGradesSetupList: GradeSetup = {
        "standard": {
            "1": "No Localizable",
            "2": "No interesado",
            "3": "Interes Bajo",
            "4": "Interes Medio",
            "5": "Alto Interes"
        }
    }

    console.log(formDataEditConnect);
    console.log(formDataEditConnect.grade_options);
    
    //Variables desde el usercontext
    const { user, token, updateToken } = useContext(UserContext);
    const clientId = user.cli_id;
    const userToken = token;

    const [crmSetupsList, setCrmSetupsList] = useState<CrmSetup>(initialCrmSetupList)
    const [mailTemplatesList, setMailTemplatesList] = useState<string[]>(initialMailTemplatesList)
    const [wabaBotsList, setWabaBotsList] = useState<WabaBot>(initialWabaBotsList)

    const [gradesSetupsList, setGradesSetupsList] = useState<GradeSetup>(initialGradesSetupList)
    const [gradesCategoryKeys, setGradesCategoryKeys] = useState<string[]>(['standard'])
    const [gradesSetupJson, setGradesSetupJson] = useState<{[key: string]: string } | null>(null);
    const [gradesSetupJsonText, setGradesSetupJsonText] = useState<string>('')

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl();

    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        text: '',
        variant: 'primary',
    });
    
    const gradeOptionsText = convertObjectsToText(formDataEditConnect.grade_options);
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        const { name, value } = event.target;

        setFormDataEditConnect(prevState => ({
            ...prevState,
            [name]: value
        }));

    }

    const handleFormSubmit = async () => {
        console.log("FormDatasConnect values:");
        console.log("mail_template: ", formDataEditConnect.mail_template);
        console.log("wab_bot: ", formDataEditConnect.wab_bot);
        console.log("crm_id: ", formDataEditConnect.crm_id);
        console.log("grade_options: ", formDataEditConnect.grade_options);

        const mail_template = formDataEditConnect.mail_template;
        const wab_bot = formDataEditConnect.wab_bot;
        const crm_id = formDataEditConnect.crm_id;
        const grade_options = formDataEditConnect.grade_options;

        try {

            const { data: jsonData, code, msg } = await campaignConnectInfoEdit(mail_template, wab_bot, crm_id, grade_options, userToken, cam_idCampaign);

            if ( code === 401 && msg === 'Token no valido' ) {

                sessionExpired(intl.formatMessage({ id: "GLOBAL.ALERT.SESSION_EXPIRED" }));
                return;

            } else {

                setAlertInfo({
                    text: code === 200 ? 'Informacion editada' : 'Algo salio mal',
                    variant: code === 200 ? 'success' : 'danger'
                });

                setTimeout(() => {
                    setAlertInfo({
                        text: '',
                        variant: ''
                    });
                    closeModalEdit();
                    getCampaignsRegisters();
                }, 3000)

            }

        } catch (error) {

            console.log(error);

        }
        
    }

    const getCrmSetups = () => {
        const crmSetupListReceived: CrmSetup = {
            client_id: 'API-DFGO-06YO',
            crm_setups: [
                {
                    crm_id: 'CRMSTP-API-001',
                    crm_name: 'HubSpot DragonCEM',
                    crm_description: '',
                },
                {
                    crm_id: 'CRMSTP-API-002',
                    crm_name: 'Zoho DragonCEM',
                    crm_description: '',
                },
                {
                    crm_id: 'CRMSTP-API-003',
                    crm_name: 'Odoo DragonCEM',
                    crm_description: '',
                }
            ]
        }

        setCrmSetupsList(crmSetupListReceived)
    }

    const getMailTemplates = async (): Promise<void> => {

        try {
            const {data: jsonData, code, msg} = await mailTemplatesListByProduct(userToken, productId)

            const mailTemplatesReceived: string[] = jsonData.data 

            const newUserToken: string = jsonData.token 

            updateToken(newUserToken)

            console.log(mailTemplatesReceived)

            setMailTemplatesList(mailTemplatesReceived)

        }catch (error){
            console.error('Error obteniendo los mail templates: ', error)
        }

        // const mailTemplatesListReceived: MailTemplate = {
        //     client_id: 'API-DFGO-06YO',
        //     mail_templates: [
        //         {
        //             mail_template_id: 'MLTMP-API-001',
        //             mail_template_name: 'b2c_notification',
        //             mail_template_description: ''
        //         },
        //         {
        //             mail_template_id: 'MLTMP-API-002',
        //             mail_template_name: 'b2b_notification',
        //             mail_template_description: ''
        //         }
        //     ]
        // }

        // setMailTemplatesList(mailTemplatesListReceived)

    }

    const getWabaBots = () => {

        const wabaBotsListReceived: WabaBot = {
            client_id: 'API-DFGO-06YO',
            wab_bots: [
                {
                    wab_bot_id: 'WBOT-API-001',
                    wab_bot_name: 'botGralDragonCEM',
                    wab_bot_description: ''
                },
                {
                    wab_bot_id: 'WBOT-API-002',
                    wab_bot_name: 'botGeneralbaahrPrueba',
                    wab_bot_description: ''
                }
            ]
        }

        setWabaBotsList(wabaBotsListReceived)
    }

    const getGradesSetups = async (): Promise<void> => {
        try {
            const {data: jsonData, code, msg} = await gradesSetupListByProduct(userToken, productId)

            const gradesSetups = jsonData.data 
            const categortKeys = Object.keys(gradesSetups)

            gradesSetups && Object.keys(gradesSetups).length > 0 && (() => {
                setGradesSetupsList(gradesSetups)
                setGradesCategoryKeys(categortKeys)
            })();

        }catch (error){
            console.error('Error obteniendo el lsitado de configuracion de calificaciones: ', error)
        }
    }

    const handleGradeSetUp = (gradeCategory: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value, type} = gradeCategory.target;
        console.log('categoria: ', value)
        console.log({gradesSetupsList})

        const gradesSetupSelected = gradesSetupsList[value]

        console.log(gradesSetupSelected)

        const formattedGradesSetupSelected = convertObjectsToText(gradesSetupSelected)

        setGradesSetupJson(gradesSetupSelected)
        setGradesSetupJsonText(formattedGradesSetupSelected)
    }

    useEffect(() => {
        getCrmSetups()
        getMailTemplates()
        getWabaBots()
        getGradesSetups()
    }, [])

    return (
        <>
        <div className="d-flex mb-6">
            <label className="col-3">Configuración CRM: </label>
                <select className="form-select form-select-solid" name="crmconfig" id="crmconfig" value='CRMSTP-API-003'>
                    {/* <option value="Hubspot DragonCEM">Hubspot DragonCEM</option> */}
                    {crmSetupsList.crm_setups.map((crmSetup, index) => (
                        <option key={crmSetup.crm_id} id={crmSetup.crm_id} value={crmSetup.crm_id}>
                            {crmSetup.crm_name}
                        </option>
                    ))}
                </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Canal CRM ID: </label>
            <input 
                type="text"
                className="form-control form-control-solid"
                id="channelcrm"
                name="channelcrm"
            />
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Mail template: </label>
            <select 
                className="form-select form-select-solid" 
                name="mail_template" 
                id="mail_template"
                value={formDataEditConnect.mail_template}
                onChange={handleInputChange}
            >
                {/* <option value={formDataEditConnect.mail_template}>{formDataEditConnect.mail_template}</option> */}
                {/* <option value="mail_option_example">Opcion de ejemplo</option> */}
                {mailTemplatesList.map((mailTemplatesList, index) => (
                    <option key={index} id={mailTemplatesList} value={mailTemplatesList}>
                        {mailTemplatesList}
                    </option>
                ))}
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Bot: </label>
            <select 
                className="form-select form-select-solid" 
                name="wab_bot" 
                id="wab_bot" 
                value={formDataEditConnect.wab_bot}
                onChange={handleInputChange}
            >
                {/* <option value="botGralPruebaDragon">botGralPruebaDragon</option>
                <option value="botGralenglish">botGralenglish</option> */}
                <option value={formDataEditConnect.wab_bot}>{formDataEditConnect.wab_bot}</option>
                {wabaBotsList.wab_bots.map((wabaBot, index) => (
                    <option key={wabaBot.wab_bot_id} id={wabaBot.wab_bot_id} value={wabaBot.wab_bot_id}>
                        {wabaBot.wab_bot_name}
                    </option>
                ))}
            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">CRM ID: </label>
            <input 
                type="text"
                className="form-control form-control-solid"
                value={formDataEditConnect.crm_id}
                id="crm_id"
                name="crm_id"
                onChange={handleInputChange}
            />
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Configuración de calificaciones: </label>
            <select 
                className="form-select form-select-solid" 
                name="grade_options" 
                id="grade_options" 
                value={formDataEditConnect.grade_options}
                onChange={(e) => {
                    handleInputChange(e)
                    handleGradeSetUp(e)

                }}
            >
                {/* <option value={formDataEditConnect.grade_options}>Configuración de {countKeys(formDataEditConnect.grade_options)} ponderadores</option> */}
                {gradesCategoryKeys.map((gradeCategory) => (
                    <option key={gradeCategory} value={gradeCategory}>
                        {gradeCategory}
                    </option>
                ))}

            </select>
            </div>
    
            <div className="d-flex mb-6">
            <label className="col-3">Ponderadores:</label>
            <textarea
                id="ponderadores"
                className="form-control form-control-solid text-muted"
                value={gradesSetupJsonText}
                readOnly
                rows={4}
            >
            </textarea>
            </div>
    
            {alertInfo.text && (
                <div className={`alert alert-${alertInfo.variant} d-flex align-items-center`} role='alert'>
                    <div>{alertInfo.text}</div>
                </div>
            )}

            <button type="submit" className='btn btn-primary btn-sm mt-8' onClick={handleFormSubmit}>Enviar</button>

        </>

    )
   
}

export { ConnectTabForm };